
import AdminEditBrand from './../../../Components/Admin/Brands/AdminEditBrand';

const AdminEditBrandPage = () => {
    return (
        <>
           <AdminEditBrand />
        </>
    )
}

export default AdminEditBrandPage
