import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import notify from '../useNotification';
import { CreateReviewAction, GetAllReviewsAction } from '../../Redux/Actions/ReviewsActions';

const AddAndGetReviewsHook = () => {

    const [loadingReview, setloadingReview] = useState(null)


    const [ReviewName, setReviewName] = useState('')
    const [ReviewRate, setReviewRate] = useState()
    const [ReviewMessage, setReviewMessage] = useState('')

    const dispatch = useDispatch();

    const getallreviews = useSelector(state => state.ReviewRed.getallreviewsres)
    const createreviewitem = useSelector(state => state.ReviewRed.createreviewitemres)

    const ChangeReviewName = (e) => {
        setReviewName(e.target.value)
    }


    const ChangeReviewRate = (e) => {
        setReviewRate(e.target.value)
    }


    const ChangeReviewMsg = (e) => {
        setReviewMessage(e.target.value)
    }

    //// On Finishing Start Up Or Create Review Item
    useEffect(() => {
        const call = async () => {
            await dispatch(GetAllReviewsAction({
                "IsActiveOnly": false
            }))
        }
        call()
    }, [])



    /// Clicking The Button & Save To Data Base 
    const addReviewItem = async (e) => {
        //  e.preventDefault();
         if (ReviewName === '' || ReviewMessage === '' || ReviewRate < 0 || ReviewRate > 5) {
            /*notify('Review Inputs Should Be Valid', 'warning')*/
            return;
        } 
        setloadingReview(true)
        await dispatch(CreateReviewAction({
            "Name": ReviewName,
            "Rate": ReviewRate,
            "Message": ReviewMessage
        }))
        setloadingReview(false)
    }

    //// On Finishing Create Review Item
    useEffect(() => {
        if (loadingReview === false) {
            //console.log(createreviewitem)
            if (createreviewitem && createreviewitem.status === 200) {
                setReviewName('')
                setReviewRate(0)
                setReviewMessage('')
                notify('تم اضافة التعليق بنجاح', 'success')
                setTimeout(() => {
                    window.location.reload()
                }, 1500);
            }
            else if (createreviewitem.status === 401) {
                notify('يرجي عمل تسجيل دخول مرة أخري لتجديد صلاحية الجلسة', 'error')
            }
        }

    }, [loadingReview])

    return [ReviewName, ChangeReviewName, ReviewRate, ChangeReviewRate,
        ReviewMessage, ChangeReviewMsg, addReviewItem, loadingReview, getallreviews]
}


export default AddAndGetReviewsHook
