import { NavItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import GetPublicGalleryHook from './../../CustomHooks/Gallery/GetPublicGalleryHook';
import { MainUrl } from './../../Redux/Types';
import UIServiceCard from './UIServiceCard';

const ServicesContainer = ({Active, Main}) => {

    const [getallpublicgallery] = GetPublicGalleryHook(Active, Main)

    // console.log(getallpublicgallery);


    return (
        <>
            <section id="portfolio" className="portfolio py-5">
                <div className="container" data-aos="fade-up">
                    <div className="portfolio-isotope" data-portfolio-filter="*" data-portfolio-layout="masonry" data-portfolio-sort="original-order">
                      
                        <div className="row gy-4 portfolio-container" data-aos="fade-left" data-aos-delay={300}>

                            {
                                getallpublicgallery && getallpublicgallery.data ?
                                    (
                                        getallpublicgallery.data.data.map((ser, index) => {
                                            return <UIServiceCard key={index} Serviceitem = {ser} />
                                        })
                                    ) : null
                            }
                           
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ServicesContainer
