import { Button } from '@mui/material';
import { Stack, Typography, TextField, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AccountInfoHook from './../../../CustomHooks/AccountInfo/AccountInfoHook';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useForm } from "react-hook-form"
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Slide } from 'react-toastify';
import UpdateAdminPasswordHook from './../../../CustomHooks/AccountInfo/UpdateAdminPasswordHook';


const AccountInfo = () => {

   const theme = useTheme()

    const [getaccountinfo] = AccountInfoHook()

    const [oldPW, ChangeoldPW, newPW, ChangenewPW, UpdatePassword] = UpdateAdminPasswordHook()

    const [expanded, setexpanded] = useState(false)

    const openAcc = () => {
        setexpanded(!expanded)
    }

    return (
        <>
            <Typography className='mb-4' sx={{ color: theme.palette.primary.dark, fontWeight: 'bold', fontSize: '25px' }}>بيانات الحساب</Typography>

            {
                getaccountinfo && getaccountinfo.data ?
                    (
                        <Box sx={{ textAlign: 'center' }}>
                            <Stack className='w-50 my-5 text-center m-auto'>

                                <TextField

                                    label="الايميل"
                                    variant="outlined"
                                    value={getaccountinfo.data.data.email}
                                    disabled
                                />

                            </Stack>

                            <Stack className='w-50 m-auto'>

                                <TextField
                                    label="اسم المستخدم"
                                    variant="outlined"
                                    value={getaccountinfo.data.data.userName}
                                    disabled
                                />

                            </Stack>

                            <Box sx={{ textAlign: '', marginTop: 8, textTransform: 'capitalize' }}>
                                <Button onClick={openAcc} sx={{ backgroundColor: theme.palette.secondary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px' }} type="" variant='contained'>تغيير كلمة مرور الأدمن</Button>
                            </Box>


                            <Accordion expanded={expanded} className='mt-5 w-50 m-auto' variant='outlined' sx={{ fontFamily: `var(--font-default)` }}>
                                <AccordionSummary
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography sx={{ color: theme.palette.primary.dark, fontFamily: `var(--font-default)`, fontWeight: 'bold' }}>تغيير كلمة المرور</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box
                                        sx=
                                        {{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 2
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <Stack direction='column' spacing={2} >

                                            <TextField
                                                sx={{ flex: 1 }}
                                                label="كلمة المرور الحالية"
                                                variant="filled"                                              
                                                value={oldPW}
                                                onChange={ChangeoldPW}
                                            />


                                            <TextField
                                                sx={{ flex: 1 }}
                                                label="كلمة المرور الجديدة"
                                                variant="filled"                                                
                                                value={newPW}
                                                onChange={ChangenewPW}
                                            />
                                        </Stack>


                                        <Box sx={{ marginTop: 3, textTransform: 'capitalize' }}>
                                            <Button type='submit' onClick={UpdatePassword} className='w-100' sx={{ backgroundColor: theme.palette.error.main, color: '#fff', fontSize: '16px' }} variant='outlined'>حفظ التعديلات</Button>
                                        </Box>


                                    </Box>
                                </AccordionDetails>
                            </Accordion>


                        </Box>
                    ) : null
            }

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Slide}
            />

        </>
    )
}

export default AccountInfo


