
import AddPageContent from './../../../Components/Admin/PageContent/AddPageContent';

const AddPContentPage = () => {
    return (
        <div>
            <AddPageContent />
        </div>
    )
}

export default AddPContentPage
