import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../Components/Assets/images/Logo.png'
import GetPublicContactInfoHook from './../../CustomHooks/Contact Info/GetPublicContactInfoHook';
import { MainUrl } from './../../Redux/Types';
import { NavLink } from "react-router-dom";


function NavBar() {

  const [getpubliccontactinfo] = GetPublicContactInfoHook()

  /// Handling Scrolling & Add Overlay To Nav Bar 

  // const [fix, setfix] = useState(false)

  // //console.log(window.screen.width);

  // const ChangeNavBackground = () => {
  //     setfix(true)
  // }


  // window.addEventListener("load", ChangeNavBackground)
  // window.addEventListener("scroll", ChangeNavBackground)
  // window.addEventListener("resize", ChangeNavBackground)

  return (
    <Navbar expand="lg" className={'down fixed-top'}>
      <Container className='nav-bar'>
        <Navbar.Brand style={{zIndex: '9999', flex: '20' }}>
          {
            getpubliccontactinfo && getpubliccontactinfo.data ?
              (
                <img className='imglogo' src={`${MainUrl}/${getpubliccontactinfo.data.data.logo}`} alt="" />
              ) : <img className='imglogo' src={logo} alt="" />
          }
        </Navbar.Brand>
        <Navbar.Toggle style={{ backgroundColor: '#FFF', borderRadius: '50%' }} aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <NavLink className="menu-item" to='/'>الرئيسية</NavLink>
            <NavLink className="menu-item" to='/about'>نبذة عنا</NavLink>
            <NavLink className="menu-item" to='/contact'>تواصل معنا</NavLink>
            <NavLink className="menu-item" to='/services'>خدماتنا</NavLink>
            <NavLink className="menu-item" to='/deposits'>الايداعات</NavLink>
            <NavLink className="menu-item" to='/team'>فريق العمل</NavLink>
            <NavLink className="menu-item" to='/jobs'>الوظائف</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;