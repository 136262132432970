import { Button, TextField, Box, Container, Stack } from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetAllPageContentAction } from '../../../Redux/Actions/PageContentActions';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme, styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';


const PContentContainerPage = () => {

    const [pageName, setpageName] = useState('Home');
    const [loading, setloading] = useState(null);
    const [apirows, setrows] = useState([])

    let temparr = [];

    const theme = useTheme();


    const columns = [
        { field: 'col1', headerName: 'الرقم', width: 20, align: 'center', headerAlign: 'center' },
        { field: 'col2', headerName: 'العنوان الرئيسي', align: 'center', headerAlign: 'center', flex : 1 },
        { field: 'col3', headerName: 'العنوان الفرعي', align: 'center', headerAlign: 'center', flex : 1 },
        { field: 'col4', headerName: 'الوصف', align: 'center', headerAlign: 'center', flex: 1 },
        { field: 'col5', headerName: 'الحالة', align: 'center', headerAlign: 'center' },
        {
            field: 'col6', headerName: 'العمليات', align: 'center', headerAlign: 'center',
            renderCell: ({ row: { col6 } }) => {
                return (
                    <Box
                        sx=
                        {{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            width: '99px',
                            verticalAlign: 'middle',
                            textAlign: 'center',
                            padding: '5px',
                            cursor: 'pointer',
                            backgroundColor: 'transparent',
                            margin: 'auto',
                            marginTop: '10px',
                            color: theme.palette.primary.dark,
                        }}>
                        <Typography sx={{ fontSize: '16px' }}>
                            {col6}
                        </Typography>


                    </Box>
                )
            }
        }
    ];

    const myref = useRef()

    let getallpagecontent = useSelector(state => state.PageContentRed.getallpagecontentres)

    const dispatch = useDispatch()


    useEffect(() => {
        if (getallpagecontent && getallpagecontent.data) {
            getallpagecontent.data.data.map((ele, index) => {
                temparr.push({ id: index, col1: ele.id, col2: ele.title, col3: ele.subTitle, col4: ele.description, col5: ele.isActive === true ? 'مفعل' : 'غير مفعل', col6: <Link sx={{ color: 'inherit' }} to={`/admin/editpagecontent/${ele.id}`}><ModeEditOutlineOutlinedIcon /></Link> })
            })
        }

        //// Store Temp Array Into Original One 
        setrows(temparr)
    }, [getallpagecontent])

    const showPageDetails = (e) => {
        if (e) {
            setpageName(e.target.value)
        }

        if (pageName === '') {
            return;
        }

    }

    useEffect(() => {

        const run = async () => {
            setloading(true)
            await dispatch(GetAllPageContentAction({
                "PageName": pageName
            }))
            // console.log(pageName);
            setloading(false)
        }
        run()

    }, [pageName])


    useEffect(() => {

        if (loading === false) {
            if (getallpagecontent && getallpagecontent.data) {
                //console.log(getallpagecontent.data.data)
            }
        }

    }, [loading])


    const data = [
        {
            value: 'Home',
            label: 'الرئيسية',
        },
        {
            value: 'About',
            label: 'نبذة عنا',
        },
        {
            value: 'Deposits',
            label: 'الايداعات',
        },
        {
            value: 'Team',
            label: 'فريق العمل',
        },
        {
            value: 'Careers',
            label: 'الوظائف',
        }
    ];

    return (
        <div>
            <Typography className='mb-4' sx={{ color: theme.palette.primary.dark, fontWeight: 'bold', fontSize: '25px' }}>محتوي الصفحات</Typography>
            <Container>
                <Stack className='d-flex w-50 fw-bold mx-auto'>
                    <TextField
                        onChange={showPageDetails}
                        id="filled-select-currency-native"
                        select
                        style={{ fontWeight: 'bold' }}
                        label="الصفحة"
                        defaultValue="الرئيسية"
                        SelectProps={{
                            native: true,
                        }}
                        variant="filled"
                    >
                        {data.map((option, key) => (
                            <option style={{ fontWeight: 'bold', padding: '10px 20px' }} className='py-4' key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>


                </Stack>


            </Container>

            {
                getallpagecontent && getallpagecontent.data && getallpagecontent.data.data.length > 0 ?
                    (
                        <div style={{ height: 'auto', width: '100%' }} className='my-5'>
                            <DataGrid rows={apirows}
                                // @ts-ignore
                                columns={columns}
                                autoHeight
                                hideFooter={true}                                
                                hideToolbar= {true}
                                sx={{ fontWeight: 'bold', fontSize: 16}}

                            />
                        </div>
                    ) : null

            }



            <Row className='d-flex mt-3'>
                <Col md='12'>
                    {
                        pageName !== '' ?
                            (
                                <Link to={`/admin/addpagecontent/${pageName}`}>
                                    <Button
                                        className='w-25 text-center m-auto px-5 d-flex justify-content-center my-5'
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                        sx={{ backgroundColor: theme.palette.secondary.dark, fontWeight: 'bold', fontSize: '18px' }}
                                    >
                                        اضافة جديد
                                        <input
                                            type="submit"
                                            hidden
                                        />
                                    </Button>

                                </Link>
                            ) : null
                    }

                </Col>
            </Row>


        </div>
    )
}

export default PContentContainerPage
