import { createnewpayuser, uplaodnewpayimage } from '../Types';

const initial = 
{
    createnewpayuserres: [],
    uploadnewpayimageres: [],
    status: ''
}

const JoinUsReducer = (state = initial , action)=>
{
    switch(action.type)
    {
        case createnewpayuser: 
            return{
                createnewpayuserres : action.data,
                status : action.status
        }
        case uplaodnewpayimage: 
            return{
                uploadnewpayimageres : action.data,
                status : action.status
        }
        default:
            return state
    }

}

export default JoinUsReducer;