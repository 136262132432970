import BaseUrl from '../../API/BaseUrl';
import { createattachment, bindsectionwithimage, updatebindsectionwithimage } from './../Types';

const AttachmentsAction =(formData)=> async(dispatch)=>
{
    const config = {
        headers : {
            "Content-type" : "multipart/form-data",
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/Attachment/v1/CreateAttachment', formData, config);
        //console.log(res.status);
        dispatch({            
            type: createattachment, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: createattachment, 
            data: 'The Error is' + e.response
        })
    }
}

const CreatePageContentAttachAction =(data)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/PageContentAttachment/v1/CreatePageContentAttachment', data, config);
        //console.log(res.status);
        dispatch({            
            type: bindsectionwithimage, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: bindsectionwithimage, 
            data: 'The Error is' + e.response
        })
    }
}


const UpdatePageContentAttachAction =(data)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/PageContentAttachment/v1/UpdatePageContentAttachment', data, config);
        //console.log(res.status);
        dispatch({            
            type: updatebindsectionwithimage, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: updatebindsectionwithimage, 
            data: 'The Error is' + e.response
        })
    }
}

export {AttachmentsAction , CreatePageContentAttachAction, UpdatePageContentAttachAction}