import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetInfoAction } from '../../Redux/Actions/LoginActions';


const ProtectedRouteHook = () => {

    const dispatch = useDispatch();
    const getinfo = useSelector(state => state.LoginRed.getinfores)

    const [userData, setuserData] = useState(localStorage.getItem(localStorage.getItem('UserLoginToken')))

    const [isAdmin, setisAdmin] = useState(null)
    const [loading, setloading] = useState(null)


    useEffect(() => {
        const run = async () => {
            setloading(true)
            await dispatch(GetInfoAction())
            setloading(false)
        }
        run()
    }, [userData])



    useEffect(() => {

        const call = async () => {
            if (loading === false) {
                // console.log(getinfo);
                if (getinfo.status != 200) {
                    setisAdmin(false)
                }
                //// In Case Of Authorized Users
                else {
                    setisAdmin(true)
                }
            }
        }
        call()
    }, [loading])


    return [userData, isAdmin];

}

export default ProtectedRouteHook
