import { updatecontactinfo, getcontactinfo, getpubliccontactinfo } from './../Types';

const initial =
{
    updatecontactinfores: [],
    getcontactinfores: [],
    getpubliccontactinfores: []
}

const ContactInfoReducer = (state = initial, action) => {
    switch (action.type) {
        case getpubliccontactinfo:
            return {
                getpubliccontactinfores: action.data,
                status: action.status
            }
        case getcontactinfo:
            return {
                getcontactinfores: action.data,
                status: action.status
            }
        case updatecontactinfo:
            return {
                updatecontactinfores: action.data,
                status: action.status
            }
        default:
            return state
    }

}

export default ContactInfoReducer;