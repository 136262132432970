import ServicesBanner from './../../Components/Utility/ServicesBanner';
import ServicesContainer from './../../Components/Services/ServicesContainer';
import NavBar from './../../Components/Utility/NavBar';
import Footer from './../../Components/Utility/Footer';



const ServicesPage = () => {
    return (
        <div>
            <NavBar />
            <ServicesBanner />
            <ServicesContainer Active = {true} Main={false} />
            <Footer />
        </div>
    )
}

export default ServicesPage
