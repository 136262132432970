import BaseUrl from '../../API/BaseUrl';
import { loginuser, getinfo } from './../Types';

const loginUser = (user) => async (dispatch) => {
    try {
        const res = await BaseUrl.post('/api/Auth/v1/login', user);
        //console.log(res.status);
        dispatch({
            type: loginuser,
            data: res,
            status: res.status
        })
    }
    catch (e) {
        dispatch({
            type: loginuser,
            data: 'The Error is' + e.response
        })
    }
}


//// Get Account Info
const GetInfoAction = () => async (dispatch) => {
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try {
        const res = await BaseUrl.get('/api/Account/v1/GetInfo', config);
        //console.log(res.status);
        dispatch({
            type: getinfo,
            data: res,
            status: res.status
        })
    }
    catch (e) {
        dispatch({
            type: getinfo,
            data: 'The Error is' + e.response
        })
    }
}



export {loginUser , GetInfoAction }


