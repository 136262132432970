import { motion } from "framer-motion";
import { Link } from 'react-router-dom';

const Hero = ({ getallpublicpagecontent }) => {

    // const text = "استقبل مدفوعاتك أسهل وأسرع مع توفير تجربة دفع أفضل لعملائك".split(" ");


    return (
        <>
            <section id="hero" className="hero mt-1 d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <h2 className='heading'>
                                {
                                    getallpublicpagecontent && getallpublicpagecontent.data ?
                                        (
                                            getallpublicpagecontent.data.data.map((item, index) => {
                                                if (item.pageName === 'Home' && item.id === 2) {
                                                    return <div key={index}>{item.title.split(" ").map((el, i) => (
                                                        <motion.span
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1 }}
                                                            transition={{
                                                                duration: 0.25,
                                                                delay: i / 10
                                                            }}
                                                            key={i}
                                                        >
                                                            {el}{" "}
                                                        </motion.span>
                                                    ))}</div>
                                                }
                                            })
                                        ) : null
                                }
                                {/* {text.map((el, i) => (
                                <motion.span
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{
                                        duration: 0.25,
                                        delay: i / 10
                                    }}
                                    key={i}
                                >
                                    {el}{" "}
                                </motion.span>
                            ))} */}
                            </h2>
                            <blockquote data-aos="fade-up" data-aos-delay={100}>
                                {
                                    getallpublicpagecontent && getallpublicpagecontent.data ?
                                        (
                                            getallpublicpagecontent.data.data.map((item, index) => {
                                                if (item.pageName === 'Home' && item.id === 2) {
                                                    return <p key={index} style={{ lineHeight: '1.9', fontSize: '17px' }}>{item.description}</p>
                                                }
                                            })
                                        ) : null
                                }
                            </blockquote>
                            <div className="d-flex" data-aos="fade-up" data-aos-delay={200}>
                                <Link to="/joinus" className="btn-get-started">انضم إلينا</Link>
                                <Link target="_blank" to="https://www.youtube.com/watch?v=9AcfzgsUTRk" className="glightbox btn-watch-video d-flex align-items-center mx-3"><i className="bi bi-play-circle" /><span style={{ marginRight: '5px' }}>تفاعل معنا</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Hero

