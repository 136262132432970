import ContactBanner from '../../Components/Utility/ContactBanner';
import ContactBody from './../../Components/Contact/ContactBody';
import Location from './../../Components/Contact/Location';
import NavBar from './../../Components/Utility/NavBar';
import Footer from './../../Components/Utility/Footer';

const ContactPage = () => {
    return (
        <div style={{overflow: 'hidden'}}>
            <NavBar />
           <ContactBanner />
           <ContactBody />
           <Location />
           <Footer />
        </div>
    )
}

export default ContactPage
