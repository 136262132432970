import * as React from 'react';
import land from '../../../Components/Assets/images/Capture.png'


export default function AdminHomePage() {
    return (
        <div>
            <img className='mt-4' style={{ borderRadius: '20px', width: '100%', height: '80vh', objectFit: 'cover' }} src={land} />
        </div>    
    );
}