import { createattachment, bindsectionwithimage, updatebindsectionwithimage } from '../Types';

const initial = 
{
    createattachmentres : [],
    bindsectionwithimageres : [],
    updatebindsectionwithimageres : [],
    status: ''
}

const AttachmentReducer = (state = initial , action)=>
{
    switch(action.type)
    {
        case createattachment: 
            return{
                createattachmentres : action.data,
                status : action.status
        }
        case bindsectionwithimage: 
            return{
                bindsectionwithimageres : action.data,
                status : action.status
        }
        case updatebindsectionwithimage: 
            return{
                updatebindsectionwithimageres : action.data,
                status : action.status
        }
        default:
            return state
    }

}

export default AttachmentReducer;