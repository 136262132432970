import * as React from 'react';
import { Container, Stack, Box, TextField, Button, Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { Slide } from 'react-toastify';
import { Row } from 'react-bootstrap';
import AdminServiceCard from './AdminServiceCard';
import AddAndGetAdminGalleryHook from './../../../CustomHooks/Gallery/AddAndGetAdminGalleryHook';
import { useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useForm } from "react-hook-form"
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


const AdminServicesContainer = () => {

    const theme = useTheme()


    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const [GalleryName, ChangeGalleryTitle, GallerySubTitle, ChangeGallerySubTitle,
        GalleryDesc, ChangeGalleryDesc, GalleryIsMain, ChangeGalleryMainOrNot,
        GalleryVideoLink, ChangeGalleryVideoLink, addGalleryItem, loadingGallery, getallgallery, creategalleryitem] = AddAndGetAdminGalleryHook()

    // console.log(getallgallery);

    return (

        <Container>

            <Typography className='mb-4' sx={{ color: theme.palette.primary.dark, fontWeight: 'bold', fontSize: '25px' }}>الخدمات</Typography>

            <Accordion variant='outlined' sx={{ fontFamily: `var(--font-default)` }}>
                <AccordionSummary                
                    expandIcon={<AddCircleOutlineOutlinedIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography sx={{ color: theme.palette.primary.dark, fontFamily: `var(--font-default)`, fontWeight: 'bold' }}>اضافة خدمة جديدة</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        component="form"
                        sx=
                        {{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 4,
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Stack direction='row' spacing={2} sx={{ fontSize: '16px' }} >

                            <TextField
                                sx={{ flex: 1 }}
                                label="عنوان الخدمة"
                                variant="outlined"
                                error={Boolean(errors.GalleryName)}
                                {...register("GalleryName", { required: true, minLength: 3 })}
                                helperText={Boolean(errors.GalleryName) === true ? 'اجباري وأقل طول 3 حروف' : null}
                                value={GalleryName}
                                onChange={ChangeGalleryTitle}
                            />

                            <TextField
                                sx={{ flex: 1 }}
                                label="العنوان الفرعي"
                                variant="outlined"
                                error={Boolean(errors.GallerySubTitle)}
                                {...register("GallerySubTitle", { required: true, minLength: 3 })}
                                helperText={Boolean(errors.GallerySubTitle) === true ? 'اجباري وأقل طول 3 حروف' : null}
                                value={GallerySubTitle}
                                onChange={ChangeGallerySubTitle}
                            />

                        </Stack>

                        <Stack>

                            <TextField
                                sx={{ flex: 1 }}
                                label="وصف الخدمة"
                                variant="outlined"
                                error={Boolean(errors.GalleryDesc)}
                                {...register("GalleryDesc", { required: true })}
                                helperText={Boolean(errors.GalleryDesc) === true ? 'اجباري' : null}
                                value={GalleryDesc}
                                onChange={ChangeGalleryDesc}
                                multiline
                            />
                        </Stack>

                        <div className='mt-4'>
                            <FormControlLabel className='text-center m-auto' control={<Checkbox checked={GalleryIsMain} onChange={ChangeGalleryMainOrNot} />} label="اظهار الخدمة في الصفحة الرئيسية" style={{ fontFamily: `var(--font-default)` }} />
                        </div>

                        {
                            loadingGallery ?
                                (
                                    <Button className ='mt-2 mb-3' disabled onClick={addGalleryItem} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px', display: 'block' }} type="" variant='contained'>اضافة خدمة</Button>
                                ) : <Button className ='mt-2 mb-3' onClick={addGalleryItem} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px', display: 'block' }} type="" variant='contained'>اضافة خدمة</Button>
                        }


                    </Box>

                </AccordionDetails>
            </Accordion>





            <Row className='g-4 d-flex my-5'>
                {
                    getallgallery && getallgallery.data ?
                        getallgallery.data.data.map((item, index) => {
                            return (<AdminServiceCard key={index} Service={item} />)
                        }) : null
                }
            </Row>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Slide}
            />


        </Container >


    )
}

export default AdminServicesContainer
