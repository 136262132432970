import NavBar from './../../Components/Utility/NavBar';
import Footer from './../../Components/Utility/Footer';
import JoinUsBanner from './../../Components/Utility/JoinUsBanner';
import JoinUSForm from './../../Components/JoinUs/JoinUSForm';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';

const JoinUsPage = () => {
    return (
        <div style={{ overflow: 'hidden' }}>
            <NavBar />
            <JoinUsBanner />
            <JoinUSForm />
            <Footer />

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Flip}
            />
        </div>
    )
}

export default JoinUsPage
