
import AdminEditService from './../../../Components/Admin/AdminServices/AdminEditService';

const AdminEditServicePage = () => {
    return (
        <div>
            <AdminEditService />
        </div>
    )
}

export default AdminEditServicePage
