import { Navigate, Outlet } from "react-router-dom"

const ProtectedRoute = ({PermissionFlag, children}) => {
        if (PermissionFlag === false)
        {
            return <Navigate to = '/login' />
        }
        else
        {
            return children ? children : <Outlet />
        }

}

export default ProtectedRoute
