import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllContactMessageActions } from '../../Redux/Actions/ContactActions';

const GetAllContactMsgHook = () => {

    const [loadingMessage, setloadingMessage] = useState(null)

   const dispatch = useDispatch();
    
    const getallcontactmsg = useSelector(state => state.ContactRed.getallcontactmsgres)

        /// Handle Get Details of All Contact Msg 
        useEffect(() => {
            const run = async () => {
                setloadingMessage(true)
                await dispatch(GetAllContactMessageActions({
                    "IsActiveOnly":true
                }))
                setloadingMessage(false)
            }
            run();
        }, [])
    

    
    /// On Finishing Posting Message

    useEffect(() => {
        const Run = async () => {
            if (loadingMessage === false) {
                //console.log(getallcontactmsg);
            }
        }
        Run()

    }, [loadingMessage])

    return [getallcontactmsg]
}


export default GetAllContactMsgHook
