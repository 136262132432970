import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetPublicGalleryAction } from '../../Redux/Actions/GalleryActions';

const GetPublicGalleryHook = (Active, Main) => {

    const dispatch = useDispatch();

    const getallpublicgallery = useSelector(state => state.GalleryRed.getallpublicgalleryres)
    
    //// On Start Up Public Gallery 
    useEffect(() => {
        const call = async () => {
            await dispatch(GetPublicGalleryAction({
                "IsActiveOnly": Active,
                "IsMainOnly": Main
            }))
        }
        call()
    }, [])


    return [getallpublicgallery]
}


export default GetPublicGalleryHook
