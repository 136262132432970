import { combineReducers } from "redux";
import BrandReducer from "./BrandReducer";
import LoginReducer from "./LoginReducer";
import AttachmentReducer from './AttachmentReducer';
import PageContentReducer from './PageContentReducer';
import ContactReducer from './ContactReducer';
import ContactInfoReducer from './ContactInfoReducer';
import GalleryReducer from './GalleryReducer';
import ReviewReducer from './ReviewReducer';
import AccountInfoReducer from './AccountInfoReducer';
import JoinUsReducer from './JoinUsReducer';

export const RootReducer = combineReducers({
    LoginRed : LoginReducer, 
    BrandRed : BrandReducer,
    AttachmentRed : AttachmentReducer,
    PageContentRed : PageContentReducer,
    ContactRed : ContactReducer,
    ContactInfoRed : ContactInfoReducer,
    GalleryRed : GalleryReducer,
    ReviewRed : ReviewReducer,
    AccountInfoRed : AccountInfoReducer,
    JoinUsRed : JoinUsReducer
});

