import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import notify from '../useNotification';
import { CreateGalleryAction, GetAllGalleryAction } from '../../Redux/Actions/GalleryActions';

const AddAndGetAdminGalleryHook = () => {

    const [loadingGallery, setloadingGallery] = useState(null)


    const [GalleryName, setGalleryName] = useState('')
    const [GallerySubTitle, setGallerySubTitle] = useState('')
    const [GalleryDesc, setGalleryDesc] = useState('')
    const [GalleryIsMain, setGalleryIsMain] = useState(false)
    const [GalleryVideoLink, setGalleryVideoLink] = useState('')


    const dispatch = useDispatch();

    const getallgallery = useSelector(state => state.GalleryRed.getallgalleryres)
    const creategalleryitem = useSelector(state => state.GalleryRed.creategalleryitemres)

    const ChangeGalleryTitle = (e) => {
        setGalleryName(e.target.value)
    }


    const ChangeGallerySubTitle = (e) => {
        setGallerySubTitle(e.target.value)
    }


    const ChangeGalleryDesc = (e) => {
        setGalleryDesc(e.target.value)
    }


    const ChangeGalleryMainOrNot = (e) => {
        setGalleryIsMain(!GalleryIsMain)
    }


    const ChangeGalleryVideoLink = (e) => {
        setGalleryVideoLink(e.target.value)
    }

    //// On Finishing Start Up Or Create Gallery Item
    useEffect(() => {
        const call = async () => {
            await dispatch(GetAllGalleryAction({
                "IsActiveOnly": false,
                "IsMainOnly": false
            }))
        }
        call()
    }, [])



    /// Clicking The Button & Save Image To Data Base 
    const addGalleryItem = async (e) => {

        e.preventDefault();
        if (GalleryName === '' || GallerySubTitle === '' || GalleryDesc === '') {
            notify('يرجي ادخال قيم مناسبة', 'warning')
            return;
        }
        setloadingGallery(true)
        await dispatch(CreateGalleryAction({
            "Title": GalleryName,
            "SubTitle": GallerySubTitle,
            "Description": GalleryDesc,
            "IsMain": GalleryIsMain,
            "VideoLink": GalleryVideoLink
        }))
        setloadingGallery(false)
    }

    //// On Finishing Create Gallery Item
    useEffect(() => {
        if (loadingGallery === false) {
            console.log(creategalleryitem)
            if (creategalleryitem && creategalleryitem.status === 200) {
                setGalleryName('')
                setGallerySubTitle('')
                setGalleryDesc('')
                setGalleryVideoLink('')
                setGalleryIsMain(false)
                notify('تم اضافة الخدمة بنجاح', 'success')
            }
            else {
                notify('Try Again Later', 'error')
            }
        }
        dispatch(GetAllGalleryAction({
            "IsActiveOnly": false,
            "IsMainOnly": false
        }))

    }, [loadingGallery])

    return [GalleryName, ChangeGalleryTitle, GallerySubTitle, ChangeGallerySubTitle,
        GalleryDesc, ChangeGalleryDesc, GalleryIsMain, ChangeGalleryMainOrNot,
        GalleryVideoLink, ChangeGalleryVideoLink, addGalleryItem, loadingGallery, getallgallery, creategalleryitem]
}


export default AddAndGetAdminGalleryHook
