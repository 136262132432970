import { createcontactmessage, getallcontactmsg } from './../Types';

const initial =
{
    createcontactmessageres: [],
    getallcontactmsgres: []

}

const ContactReducer = (state = initial, action) => {
    switch (action.type) {
        case createcontactmessage:
            return {
                createcontactmessageres: action.data,
                status: action.status
            }
        case getallcontactmsg:
            return {
                getallcontactmsgres: action.data,
                status: action.status
            }
        default:
            return state
    }

}

export default ContactReducer;