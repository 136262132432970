import { Typography, Box, TextField, Stack, Button, FormLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useForm } from "react-hook-form"
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import EditPageContentHook from './../../../CustomHooks/PageContent/EditPageContentHook';
import { Row, Col } from 'react-bootstrap'
import { MainUrl } from './../../../Redux/Types';


const EditPageContent = () => {

    const { id } = useParams()

    const [getpagecontentwithid, pagename, pagetitle, changePageTitle, pagesubtitle, changePageSubTitle,
        pagedesc, changePageDesc, pagestatus, changePageStatus, pageimg, pageImgCaption, onImageChange,
        updateSection, loadingimg, loadingpagesection, loadingitem, loadingcreatebind] = EditPageContentHook(id)

    // console.log(id);

    const theme = useTheme();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()


    const onSubmit = () => {
        /* console.log('Done');*/
        /*handleClick();*/

    }

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <div className='my-5'>

            <Typography className='mb-5' sx={{ color: theme.palette.primary.dark, fontWeight: 'bold', fontSize: '25px' }}>تعديل محتوي رقم : {id} ، صفحة : {pagename}</Typography>

            <Box
                onSubmit={handleSubmit(onSubmit)}
                component="form"
                sx=
                {{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                }}
                noValidate
                autoComplete="off"
            >
                <Stack direction='row' spacing={2} sx={{ fontSize: '16px' }} >

                    <TextField
                        sx={{ flex: 1 }}
                        label="العنوان الرئيسي"
                        variant="outlined"
                        error={Boolean(errors.pagetitle)}
                        {...register("pagetitle", { required: true, minLength: 3 })}
                        helperText={Boolean(errors.pagetitle) === true ? 'اجباري وأقل طول 3 حروف' : null}
                        value={pagetitle}
                        onChange={changePageTitle}
                    />

                    <TextField
                        sx={{ flex: 1 }}
                        label="العنوان الفرعي"
                        variant="outlined"
                        error={Boolean(errors.pagesubtitle)}
                        {...register("pagesubtitle", { required: true, minLength: 3 })}
                        helperText={Boolean(errors.pagesubtitle) === true ? 'اجباري وأقل طول 3 حروف' : null}
                        value={pagesubtitle}
                        onChange={changePageSubTitle}
                    />



                </Stack>

                <Stack>

                    <TextField
                        sx={{ flex: 1 }}
                        label="الوصف"
                        variant="outlined"
                        error={Boolean(errors.desc)}
                        {...register("desc", { required: true })}
                        helperText={Boolean(errors.desc) === true ? 'اجباري' : null}
                        value={pagedesc}
                        onChange={changePageDesc}
                        multiline
                    />
                </Stack>

                <Row className='d-flex mt-5'>
                    <Col md='1' style={{ alignSelf: 'center' }}>
                        <FormLabel className='fw-bold mt-2'>الصورة</FormLabel>
                    </Col>
                    <Col md='11'>
                        {
                            getpagecontentwithid && getpagecontentwithid.data ?
                                (
                                    <label htmlFor='upload'>
                                        <img
                                            src={pageimg != '' ? pageimg : `${MainUrl}/${getpagecontentwithid.data.data.image}`}
                                            alt=''
                                            style={{ cursor: 'pointer', width: '120px', heigth: '140px', borderRadius: '10px' }}
                                        />
                                    </label>) : null
                        }

                        <input style={{ opacity: '0', position: 'absolute' }} type='file' id='upload'
                            onChange={onImageChange}
                        />
                        <span style={{ margin: 'auto 20px', fontWeight: 'bold' }} dir='rtl'>
                            {pageImgCaption}
                        </span>
                    </Col>
                </Row>



                <FormControlLabel className='text-center m-auto' control={<Checkbox checked={pagestatus} onChange={changePageStatus} />} label="اظهار علي السايت" />


                {
                    loadingimg || loadingpagesection || loadingitem || loadingcreatebind ?
                        (
                            <Button disabled onClick={updateSection} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px', display: 'block' }} type="" variant='contained'>حفظ التعديلات</Button>
                        ) : <Button onClick={updateSection} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px', display: 'block' }} type="" variant='contained'>حفظ التعديلات</Button>
                }

            </Box>


        </div>
    )
}

export default EditPageContent
