import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import notify from './../../CustomHooks/useNotification';
import { AttachmentsAction, CreatePageContentAttachAction } from './../../Redux/Actions/AttachmentsAction';
import { CreatePageContentAction } from '../../Redux/Actions/PageContentActions';
import { CreateContactMessage } from '../../Redux/Actions/ContactActions';

const CreateMessageHook = () => {

    const [loadingMessage, setloadingMessage] = useState(null)

    const [Name, setName] = useState('')
    const [Phone, setPhone] = useState('')
    const [Message, setMessage] = useState('')
    const [ModalClosed, setModalClosed] = useState(null)


   const dispatch = useDispatch();
    
    const createcontactmessage = useSelector(state => state.ContactRed.createcontactmessageres)

    const changeName = (e) => {
        setName(e.target.value)
    }

    const changePhone = (e) => {
        setPhone(e.target.value)
    }

    const changeMessage = (e) => {
        setMessage(e.target.value)
    }

   

    /// Clicking The Button & Save Contact Message
    const postmessage = async (e) => {
        // e.preventDefault();
        if (Name === '') {
            notify('يرجي استكمال البيانات', 'warning')
            return;
        }
        if (Phone === '') {
            notify('يرجي استكمال البيانات', 'warning')
            return;
        }
        if (Message === '') {
            notify('يرجي استكمال البيانات', 'warning')
            return;
        }
        setloadingMessage(true)
        await dispatch(CreateContactMessage({
            "Name": Name,
            "Phone": Phone,
            "Message": Message
        }))
        setloadingMessage(false)
    }


    /// On Finishing Posting Message

    useEffect(() => {
        const Run = async () => {
            if (loadingMessage === false) {
                // console.log(createcontactmessage);
                if (createcontactmessage.status === 200) {
                    setName('')
                    setPhone('')
                    setMessage('')
                    setModalClosed('true')
                    notify('تم ارسال الرسالة بنجاح', 'success')
                }
                else {
                    notify('يوجد مشكلة ، حاول لاحقا', 'error')
                }
            }
        }
        Run()

    }, [loadingMessage])

    return [Name, changeName, Phone, changePhone, Message , changeMessage, postmessage, ModalClosed]
}


export default CreateMessageHook
