import BaseUrl from '../../API/BaseUrl';
import { updatecontactinfo, getcontactinfo, getpubliccontactinfo } from './../Types';


/// Update Contact Info
const UpdateContactInfoAction =(contactData)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/ContactInfo/v1/UpdateContactInfo', contactData, config);
        //console.log(res.status);
        dispatch({            
            type: updatecontactinfo, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: updatecontactinfo, 
            data: 'The Error is' + e.response
        })
    }
}


/// Get Contact Info With Token
const GetContactInfoAction =(contactData)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/ContactInfo/v1/GetContactInfo', contactData, config);
        //console.log(res);
        dispatch({            
            type: getcontactinfo, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getcontactinfo, 
            data: 'The Error is' + e.response
        })
    }
}


/// Get Contact Info Without Token
const GetPublicContactInfoAction =(Contactdata)=> async(dispatch)=>
{
    const config = {
        headers : {
            headers: { 'Content-Type': 'application/json' }
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/ContactInfo/v1/GetPublicContactInfo', Contactdata, config);
        //console.log(res);
        dispatch({            
            type: getpubliccontactinfo, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getpubliccontactinfo, 
            data: 'The Error is' + e.response
        })
    }
}





export { UpdateContactInfoAction, GetContactInfoAction, GetPublicContactInfoAction }