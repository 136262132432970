
import ContactInfo from '../../../Components/Admin/ContactInfo/ContactInfo';

const ContactInfoPage = () => {
    return (
        <div>
            <ContactInfo />
        </div>
    )
}

export default ContactInfoPage
