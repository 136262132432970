import Slider from "react-slick";
import { useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Container from 'react-bootstrap/Container';
import GetAllPublicBrandsHook from './../../CustomHooks/Brands/GetAllPublicBrandsHook';
import { MainUrl } from './../../Redux/Types';

const HomeBrands = () => {

  const [getallpublicbrands] = GetAllPublicBrandsHook();

  /// Handling Scrolling & Add Overlay To Nav Bar 

  const [fix, setfix] = useState('lg')

  //console.log(window.screen.width);

  const ChangeNumberOfSlides = () => {
    if ((window.screen.width < 992) && (window.screen.width >= 500)) {
      setfix('md')
    }
    else if (window.screen.width < 500) {
      setfix('xm')
    }
    else {
      setfix('lg')
    }
  }


  window.addEventListener("load", ChangeNumberOfSlides)
  window.addEventListener("resize", ChangeNumberOfSlides)

  const settings = {
    speed: 700,
    slidesToShow: fix === 'lg' ? 10 : fix === 'md' ? 5 : 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 500,
  };

  return (
    <Container className='text-center m-auto my-5'>
      <div className='section-header'>
        <h2>شركاء نجاح موجة</h2>
      </div>
      <Slider {...settings}>

        {
          getallpublicbrands && getallpublicbrands.data ?
            (
              getallpublicbrands.data.data.map((item, index) => {
                return <div key={index}><img style={{ width: '90px', height: '90px' }} src={`${MainUrl}/${item.image}`} alt="" /></div>
              })
            ) : null
        }

      </Slider>
    </Container>
  );
};

export default HomeBrands;