import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetPublicContactInfoAction } from '../../Redux/Actions/ContactInfoActions';


const GetPublicContactInfoHook = () => {

    const dispatch = useDispatch();

    const [loadingData, setloadingData] = useState(null)

    const getpubliccontactinfo = useSelector(state => state.ContactInfoRed.getpubliccontactinfores)

    useEffect(() => {
        const run = async () => {
            setloadingData(true)
            await dispatch(GetPublicContactInfoAction({}));
            setloadingData(false)
        }
        run();
    }, [])

    useEffect(() => {
        const run = async () => {
            if (loadingData === false) {
                /// console.log(getpubliccontactinfo);
            }
        }
        run();
    }, [loadingData])

    return [getpubliccontactinfo]
}


export default GetPublicContactInfoHook
