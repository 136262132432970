import GetAllPublicReviewsHook from './../../CustomHooks/Reviews/GetAllPublicReviewsHook';
import StarIcon from '@mui/icons-material/Star';


import React from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBTypography
} from "mdb-react-ui-kit";

export default function HomeReviews() {

    function DisplayRate(max) {
        let items = []
        if (max > 5) {
            max = 5
        }
        for (let i = 0; i < max; i++) {
            items.push(
                <li key={i}>
                    <StarIcon className="text-warning" />
                </li>
            );
        }

        return <>{items}</>;
    }

    const [getallpublicreviews] = GetAllPublicReviewsHook()

    // console.log(getallpublicreviews);

    return (
        <MDBContainer className="pt-1 pb-4">
            <MDBRow className="d-flex justify-content-center">
                <MDBCol md="10" xl="8" className="text-center">
                    <div className='section-header'>
                        <h2>آراء عملاء موجة</h2>
                    </div>
                    <p style={{fontSize: '1.1rem',lineHeight: '2.1', marginTop: '-20px'}} className="mb-2 mb-md-5 pb-md-0 fw-bold">
                    هناك الكثير من الطرق للتعبير عن الامتنان. فتباين طريقتك يضمن لك أن يُدرك عملاؤك أنك بذلت وقتك لاستيعاب أفكارهم والاستجابة لها، <strong style={{color: '#fc6c14'}}>موجة</strong> ممتنة جدا لكل عملائها الكرام 
                    </p>
                </MDBCol>
            </MDBRow>
            <MDBRow className="text-center d-flex align-items-stretch">
                {
                    getallpublicreviews && getallpublicreviews.data ?
                        (
                            getallpublicreviews.data.data.slice(0,3).map((item, index) => {
                                return <MDBCol key={index} md="4" className="mb-5 mb-md-0 d-flex align-items-stretch">
                                    <MDBCard className="testimonial-card">
                                        <div
                                            className="card-up"
                                            style={{ backgroundColor: "#9d789b" }}
                                        ></div>
                                        <div className="avatar mx-auto bg-white">
                                            <img
                                                src= {`/assets/img/Rev${index+1}.jpg`}
                                                className="rounded-circle img-fluid"
                                            />
                                        </div>
                                        <MDBCardBody>
                                            <h4 style={{color : '#F73918' ,fontWeight: 'normal', fontSize: '17px'}} className="mb-4 mt-3 fs-italic reviewername">{item.name}</h4>
                                            <hr />
                                            <p style={{lineHeight: '2.2'}} className="dark-grey-text mt-4">
                                                {item.message}
                                            </p>
                                            <MDBTypography
                                                listUnStyled
                                                className="d-flex justify-content-center mb-0 p-0"
                                            >
                                                {
                                                    DisplayRate(item.rate)
                                                }

                                            </MDBTypography>
                                        </MDBCardBody>

                                    </MDBCard>
                                </MDBCol>
                            })
                        ) : null
                }
            </MDBRow>
        </MDBContainer>
    );
}