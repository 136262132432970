import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetReviewDetailsAction, UpdateReviewAction } from '../../Redux/Actions/ReviewsActions';
import notify from './../../CustomHooks/useNotification';


const EditReviewItemHook = (reviewID) => {

    const dispatch = useDispatch();

    const [loading, setloading] = useState(null)


    const [ReviewName, setReviewName] = useState('')
    const [ReviewRate, setReviewRate] = useState(0)
    const [ReviewMessage, setReviewMessage] = useState('')
    const [ReviewIsActive, setReviewIsActive] = useState(false)


    let getonereview = useSelector(state => state.ReviewRed.getonereviewres)
    let updatereviewitem = useSelector(state => state.ReviewRed.updatereviewitemres)

    /// Handle Get Details of Review Item Action With ID Parameter  
    useEffect(() => {
        const run = async () => {
            await dispatch(GetReviewDetailsAction({
                "Id": reviewID
            }))
        }
        run()
    }, [])

    //// Assign Name To Text Box Value 
    useEffect(() => {
        const run = () => {
            if (getonereview) {
                if (getonereview.data) {
                    setReviewName(getonereview.data.data.name)
                    setReviewRate(getonereview.data.data.rate)
                    setReviewMessage(getonereview.data.data.message)
                    setReviewIsActive(getonereview.data.data.isActive)
                }
            }
        }
        run()
    }, [getonereview])


    const ChangeReviewName = (e) => {
        setReviewName(e.target.value)
    }


    const ChangeReviewRate = (e) => {
        setReviewRate(e.target.value)
    }


    const ChangeReviewMsg = (e) => {
        setReviewMessage(e.target.value)
    }

    const ChangeActive = (e) => {
        setReviewIsActive(!ReviewIsActive)
    }

    /// Clicking The Button & Save Item To Data Base 
    const UpdateReview = async (e) => {
        //e.preventDefault();
        if (ReviewName === '' || ReviewMessage === '' || ReviewRate < 0 || ReviewRate > 5) {
            //notify('Review Inputs Should Be Valid', 'warning')
            return;
        }
        setloading(true)
        await dispatch(UpdateReviewAction({
            "Id": reviewID,
          "Name": ReviewName,
          "Rate": ReviewRate,
          "Message": ReviewMessage,
          "IsActive": ReviewIsActive
      }))
        setloading(false)
    }

    //// On Finishing Edit Review Item
    useEffect(() => {
        const Run = () => {
            if (loading === false) {
                //console.log(updatereviewitem);
                 if (updatereviewitem && updatereviewitem.status === 200) {
                    setReviewName('')                   
                    setReviewRate(0)                   
                    setReviewMessage('')                   
                    setReviewIsActive(false)                   
                    //notify('Item Updated Successfully', 'success')
                    setTimeout(() => {
                        window.location.pathname = "/admin/reviews"
                    }, 1500);
                }
                else if (updatereviewitem.status === 401) {
                    notify('يرجي تسجيل الدخول مرة أخري لتجديد صلاحية الجلسة', 'error')
                }
            }
        }

        Run()

    }, [loading])


    return [getonereview, ReviewName, ChangeReviewName,
        ReviewRate, ChangeReviewRate, ReviewMessage, ChangeReviewMsg,
        ReviewIsActive, ChangeActive, UpdateReview, loading]
}


export default EditReviewItemHook
