import { Typography, Box, TextField, Stack, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useForm } from "react-hook-form"
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';
import { useParams } from 'react-router-dom';
import EditReviewItemHook from './../../../CustomHooks/Reviews/EditReviewItemHook';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


const EditReviewComponent = () => {

    const { id } = useParams()

    const [getonereview, ReviewName, ChangeReviewName,
        ReviewRate, ChangeReviewRate, ReviewMessage, ChangeReviewMsg,
        ReviewIsActive, ChangeActive, UpdateReview, loading] = EditReviewItemHook(id)

    const theme = useTheme();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()


    const onSubmit = () => {
        /* console.log('Done');*/
        /*handleClick();*/

    }

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <div className='my-5'>

            <Typography className='mb-5' sx={{ color: theme.palette.primary.dark, fontWeight: 'bold', fontSize: '25px' }}>تعديل تعليق رقم : #{id}</Typography>

            <Box
                onSubmit={handleSubmit(onSubmit)}
                component="form"
                sx=
                {{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                }}
                noValidate
                autoComplete="off"
            >
                <Stack direction='row' spacing={2} sx={{ fontSize: '16px' }} >

                    {
                        getonereview && getonereview.data ?
                            (
                                <TextField
                                    sx={{ flex: 1 }}
                                    label="الاسم"
                                    variant="outlined"
                                    error={Boolean(errors.name)}
                                    {...register("name", { required: true, minLength: 3 })}
                                    helperText={Boolean(errors.name) === true ? 'اجباري وأقل طول 3 حروف' : null}
                                    value={ReviewName}
                                    onChange={ChangeReviewName}
                                    focused
                                />
                            ) : (
                                <TextField
                                    sx={{ flex: 1 }}
                                    label="الاسم"
                                    variant="outlined"
                                    error={Boolean(errors.name)}
                                    {...register("name", { required: true, minLength: 3 })}
                                    helperText={Boolean(errors.name) === true ? 'اجباري وأقل طول 3 حروف' : null}
                                    value=''
                                    focused
                                />
                            )
                    }

                    {
                        getonereview && getonereview.data ?
                            (
                                <TextField
                                    sx={{ flex: 1 }}
                                    label="درجة التقييم"
                                    type='number'
                                    variant="outlined"
                                    error={Boolean(errors.rate)}
                                    {...register("rate", { required: true, min: 0, max: 5 })}
                                    helperText={Boolean(errors.rate) === true ? 'لا يمكن قبول قيمة أقل من الصفر أو أعلي من 5' : null}
                                    value={ReviewRate}
                                    onChange={ChangeReviewRate}
                                />
                            ) :
                            (
                                <TextField
                                    sx={{ flex: 1 }}
                                    label="درجة التقييم"
                                    type='number'
                                    variant="outlined"
                                    error={Boolean(errors.rate)}
                                    {...register("rate", { required: true, min: 0, max: 5 })}
                                    helperText={Boolean(errors.rate) === true ? 'لا يمكن قبول قيمة أقل من الصفر أو أعلي من 5' : null}
                                    value='0'
                                />
                            )
                    }

                </Stack>


                {
                    getonereview && getonereview.data ?
                        (
                            <TextField
                                multiline
                                id="filled-basic"
                                label="محتوي التقييم"
                                variant="outlined"
                                error={Boolean(errors.message)}
                                {...register("message", { required: true, minLength: 5 })}
                                helperText={Boolean(errors.message) === true ? 'اجباري وأقل طول 5 حروف' : null}
                                value={ReviewMessage}
                                onChange={ChangeReviewMsg}
                            />
                        ) : (
                            <TextField
                                multiline
                                id="filled-basic"
                                label="محتوي التقييم"
                                variant="outlined"
                                error={Boolean(errors.message)}
                                {...register("message", { required: true, minLength: 5 })}
                                helperText={Boolean(errors.message) === true ? 'اجباري وأقل طول 5 حروف' : null}
                                value=''
                            />
                        )
                }

                {
                    getonereview && getonereview.data ?
                        (
                            <FormControlLabel className='text-center m-auto' control={<Checkbox checked={ReviewIsActive} onChange={ChangeActive} />} label="اظهار التعليق علي السايت" />


                        ) : (
                            <FormControlLabel control={<Checkbox checked={false} />} label="اظهار التعليق علي السايت" />

                        )
                }

                <Button onClick={UpdateReview} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px', display: 'block' }} type="" variant='contained'>حفظ التعديلات</Button>
                {
                    loading === false ?
                        (
                            <Snackbar
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                open={true} autoHideDuration={2000} onClose={handleClose}>
                                <Alert
                                    onClose={handleClose}
                                    severity="success"
                                    variant="filled"
                                    sx={{ width: '100%' }}
                                >
                                    تم تعديل التعليق بنجاح
                                </Alert>
                            </Snackbar>
                        ) : null
                }
            </Box>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Flip}
            />

        </div>
    )
}

export default EditReviewComponent
