import ServiceDetailsBanner from './../../Components/Utility/ServiceDetailsBanner';
import ServiceDetails from './../../Components/Services/ServiceDetails';
import NavBar from './../../Components/Utility/NavBar';
import Footer from './../../Components/Utility/Footer';


const ServiceDetailsPage = () => {
    return (
        <div>
            <NavBar />
            <ServiceDetailsBanner />
            <ServiceDetails />
            <Footer />
        </div>
    )
}

export default ServiceDetailsPage
