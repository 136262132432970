import CreateMessageHook from './../../CustomHooks/Contact/CreateMessageHook';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';
import GetPublicContactInfoHook from './../../CustomHooks/Contact Info/GetPublicContactInfoHook';

const ContactBody = () => {

    const [Name, changeName, Phone, changePhone, Message, changeMessage, postmessage] = CreateMessageHook()

    const [getpubliccontactinfo] = GetPublicContactInfoHook()


    return (
        <>
            <section id="contact" className="contact">
                <div className="container position-relative" data-aos="fade-up">
                    <div className="row gy-4 d-flex justify-content-end">
                        <div className="col-lg-5" data-aos="fade-up" data-aos-delay={100}>
                            <div className="info-item d-flex">
                                <i className="bi bi-geo-alt flex-shrink-0 mx-3" />
                                <div>
                                    <h4>العنوان</h4>
                                    <p className='fw-bold fs-6'> 1 شارع الثورة الهرم ، الجيزة</p>
                                </div>
                            </div>{/* End Info Item */}
                            <div className="info-item d-flex">
                                <i className="bi bi-envelope flex-shrink-0 mx-3" />
                                <div>
                                    <h4>البريد الالكتروني</h4>
                                    <p className='fw-bold fs-6'>info@mogapayment.com</p>
                                </div>
                            </div>{/* End Info Item */}
                            <div className="info-item d-flex">
                                <i className="bi bi-phone flex-shrink-0 mx-3" />
                                <div>
                                    <h4>الدعم</h4>
                                    {
                                        getpubliccontactinfo && getpubliccontactinfo.data ?
                                            (
                                                <p className='fw-bold fs-6'>{getpubliccontactinfo.data.data.phone}</p>
                                            ) : <p className='fw-bold fs-6'>01207776474</p>
                                    }
                                </div>
                            </div>{/* End Info Item */}
                        </div>


                        <div className="col-lg-6" data-aos="fade-up" data-aos-delay={250}>
                            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <input onChange={changeName} value={Name} type="text" name="name" className="form-control fw-bold" id="name" placeholder="الاسم" />
                                    </div>
                                    <div className="col-md-6 form-group mt-3 mt-md-0">
                                        <input type="email" className="form-control fw-bold" name="email" id="email" placeholder="البريد الالكتروني" required />
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <input onChange={changePhone} value={Phone} type="number" className="form-control fw-bold" name="subject" id="subject" placeholder="رقم الهاتف" required />
                                </div>
                                <div className="form-group mt-3">
                                    <textarea onChange={changeMessage} value={Message} className="form-control fw-bold" name="message" rows={5} placeholder="موضوع الرسالة" required />
                                </div>
                                <div className="text-center"><button onClick={postmessage} type="submit">ارسال الرسالة</button></div>
                            </form>
                        </div>{/* End Contact Form */}
                    </div>
                </div>

                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={true}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    transition={Flip}
                />

            </section>{/* End Contact Section */}

        </>
    )
}

export default ContactBody
