import { createbrand, getallbrands, getallactivebrands, editbrand, getonebrand, getallpublicbrands } from './../Types';

const initial = 
{
    createbrandres : [],
    allbrandsres : [],
    allactivebrandsres : [] ,
    updatebrandres : [],
    onebrandres : [],
    getallpublicbrandsres : [],
    status: ''
}

const BrandReducer = (state = initial , action)=>
{
    switch(action.type)
    {
        case createbrand: 
            return{
                createbrandres : action.data,
                status : action.status
        }
        case getallbrands: 
            return{
                allbrandsres : action.data,
                status : action.status
        }
        case getallactivebrands: 
            return{
                allactivebrandsres : action.data,
                status : action.status
        }
        case editbrand: 
            return{
                updatebrandres : action.data,
                status : action.status
        }
        case getonebrand: 
            return{
                onebrandres : action.data,
                status : action.status
        }
        case getallpublicbrands: 
            return{
                getallpublicbrandsres : action.data,
                status : action.status
        }
        default:
            return state
    }

}

export default BrandReducer;