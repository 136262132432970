import { useParams } from 'react-router-dom';
import GetPublicGalleryHook from './../../CustomHooks/Gallery/GetPublicGalleryHook';
import { useState, useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { MainUrl } from './../../Redux/Types';

const ServiceDetails = () => {

    const { id } = useParams()

    const [getallpublicgallery] = GetPublicGalleryHook()

    const [title, settitle] = useState('')
    const [desc, setdesc] = useState('')
    const [galAlbums, setgalAlbums] = useState([{}])

    const settings = {
        speed: 1000,
        slidesToShow: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: (
            <div>
                <div className="next-slick-arrow rotate-180">
                    <svg xmlns="http://www.w3.org/2000/svg" stroke="black" height="24" viewBox="0 -960 960 960" width="24"><path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z" /></svg>
                </div>
            </div>
        ),

        prevArrow: (
            <div>
                <div className="next-slick-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" stroke="black" height="24" viewBox="0 -960 960 960" width="24"><path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z" /></svg>

                </div>
            </div>
        )
    };


    useEffect(() => {
        const run = () => {
            if (getallpublicgallery) {
                if (getallpublicgallery.data) {
                    getallpublicgallery.data.data.filter((item) => {
                        if (item.id == id) {
                            settitle(item.title)
                            setdesc(item.description)
                            if (item.galleryAlbum && item.galleryAlbum.length > 0) {
                                setgalAlbums(item.galleryAlbum)
                            }
                        }
                    })
                }
            }
        }
        run()
    }, [getallpublicgallery])

    return (
        <>

            {/* ======= Portfolio Details Section ======= */}
            <section id="portfolio-details" className="portfolio-details">
                <div className="container" data-aos="fade-up">
                    <div className="row gy-4">
                        <div className="col-lg-6">
                            <Slider {...settings}>
                                {
                                    galAlbums && galAlbums.length > 0 ?
                                        galAlbums.map((galitem, index) => {
                                            return <div key= {index} className='w-100 m-auto d-flex justify-content-center'>
                                                <img key={index} style={{ maxWidth: '100%', height: '450px', objectFit: 'fill' }} src={`${MainUrl}/${galitem.image}`} alt="" />
                                            </div>


                                        }) : null
                                }
                            </Slider>
                        </div>
                        <div className="col-lg-6">
                            <div className="portfolio-info">
                                <h2 className='my-4 text-center' style={{ color: '#fc6c14' }}>{title}</h2>
                                <p className='fw-bold' style={{ lineHeight: '2.3', opacity: '0.8' }}>{desc}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>{/* End Portfolio Details Section */}

        </>
    )
}

export default ServiceDetails
