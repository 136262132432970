import AdminServicesContainer from './../../../Components/Admin/AdminServices/AdminServicesContainer';

const AdminServicesPage = () => {
    return (
        <div>
            <AdminServicesContainer />
        </div>
    )
}

export default AdminServicesPage
