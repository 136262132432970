import AdminBrandsContainer from './../../../Components/Admin/Brands/AdminBrandsContainer';

const AdminBrandsPage = () => {
    return (
        <>
            <AdminBrandsContainer />
        </>
    )
}

export default AdminBrandsPage
