import { createnewpayuser, uplaodnewpayimage } from '../Types';
import AgentUrl from './../../API/AgentUrl';


const UploadNewPayImage =(formData)=> async(dispatch)=>
{
    try
    {
        const res = await AgentUrl.post('/api/V1/Attachment/Images/AddSingleFile', formData);
        //console.log(res.status);
        dispatch({            
            type: uplaodnewpayimage, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: uplaodnewpayimage, 
            data: 'The Error is' + e.response
        })
    }
}


const CreateUserOnNewPay =(userDate)=> async(dispatch)=>
{
    try
    {
        const res = await AgentUrl.post('/api/V1/Agent/CreateNewAgent', userDate);
        // console.log(res);
        dispatch({            
            type: createnewpayuser, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: createnewpayuser, 
            data: 'The Error is' + e.response
        })
    }
}


export {UploadNewPayImage, CreateUserOnNewPay}