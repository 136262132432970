import { Row, Col } from "react-bootstrap"

const Location = () => {
    return (
        <Row>
                <Col lg='12' xm='12'>
                        <iframe
                            title='Moga Location'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13820.857412536177!2d31.15610571034685!3d30.00200089593667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14584555383b8c9d%3A0x1cce450d6bd9edd1!2z2KfZhNmB2LHYuSDYp9mE2LHYptmK2LPZiiDZhNi02LHZg9mHINmF2YjYrNmHINmE2K7Yr9mF2KfYqiDYp9mE2K_Zgdi5INin2YTYpdmE2YPYqtix2YjZhtmK!5e0!3m2!1sar!2seg!4v1712529851707!5m2!1sar!2seg" width="600" height="450" style="border:0;" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                            height="450"
                            width="100%"
                            style={{ border: '0' }}>
                        </iframe>
                </Col>

        </Row>
    )
}

export default Location
