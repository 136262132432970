
import AboutBanner from './../../Components/Utility/AboutBanner';
import AboutBody from './../../Components/About/AboutBody';
import NavBar from './../../Components/Utility/NavBar';
import Footer from './../../Components/Utility/Footer';

const AboutPage = () => {
    return (
        <div>
            <NavBar />
           <AboutBanner /> 
           <AboutBody /> 
           <Footer />
        </div>
    )
}

export default AboutPage
