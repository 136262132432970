import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import notify from './../../CustomHooks/useNotification';
// import up from './../../Assets/images/UploadPageContent.jpg'
import { AttachmentsAction, CreatePageContentAttachAction } from './../../Redux/Actions/AttachmentsAction';
import { CreatePageContentAction } from '../../Redux/Actions/PageContentActions';

const AddPageContentHook = (sectionName) => {

    const [loadingimg, setloadingimg] = useState(null)
    const [loadingpagesection, setloadingpagesection] = useState(null)
    const [loadingitem, setloadingitem] = useState(null)


    const [pagetitle, setpagetitle] = useState('')
    const [pagesubtitle, setpagesubtitle] = useState('')
    const [pagedesc, setpagedesc] = useState('')
    const [pageImg, setpageImg] = useState('')
    const [pageImgCaption, setpageImgCaption] = useState('')
    const [attachmentID, setattachmentID] = useState(0)
    const [pageSectionID, setpageSectionID] = useState(0)
    const [selectedFile, setselectedFile] = useState(null)
    const myFormData = new FormData()

    const dispatch = useDispatch();
    const createpagecontent = useSelector(state => state.PageContentRed.createpagecontentres)
    const createattachment = useSelector(state => state.AttachmentRed.createattachmentres)
    const bindsectionwithimage = useSelector(state => state.AttachmentRed.bindsectionwithimageres)



    const changePageTitle = (e) => {
        setpagetitle(e.target.value)
    }

    const changePageSubTitle = (e) => {
        setpagesubtitle(e.target.value)
    }

    const changePageDesc = (e) => {
        setpagedesc(e.target.value)
    }

    ///// On Change Image 
    const onImageChange = async (e) => {
        /// In Case User Selects File (Image)
        /// Change Title Of Image & Image Thumbnail

        if (e.target.files.length > 0) {
            //console.log(URL.createObjectURL(e.target.files[0]))

            // Assign Image Source to Cat Img

            await setpageImg(URL.createObjectURL(e.target.files[0]))

            // Assign Span Title To Cat Name 
            await setpageImgCaption(e.target.files[0].name)

            await setselectedFile(e.target.files[0])
        }
        else {
            notify('يجب اختيار صورة صالحة', 'warning')
        }

    }


    /// Clicking The Button & Save Image To Data Base 
    const addSection = async (e) => {
        e.preventDefault();
        if (pagetitle === '' || pagetitle.length < 2) {
            notify('عنوان رئيسي غير سليم أو أقل من الطول المناسب', 'warning')
            return;
        }
        if (pagesubtitle === '') {
            notify('عنوان فرعي غير سليم', 'warning')
            return;
        }
        if (pagedesc === '') {
            notify('وصف غير سليم', 'warning')
            return;
        }
        if (selectedFile === null) {
            notify('يجب اختيار صورة صالحة', 'warning')
            return;
        }
        setloadingimg(true)
        await myFormData.append("AttachmentType", 1)
        await myFormData.append("File", selectedFile);
        await dispatch(AttachmentsAction(myFormData))
        setloadingimg(false)
    }


    /// On Finishing Upload Image 
    useEffect(() => {
        if (loadingimg === false) {
            // console.log(createattachment);
            if (createattachment.status === 200) {
                if (createattachment.data && createattachment.data.data) {
                    /// Calling Another API To Create One Page Section
                    setattachmentID(createattachment.data.data)
                }
            }
        }
    }, [loadingimg])

    //// Create Page Section Function To Insert Text Into Data Base 
    useEffect(() => {
        const run = async () => {
            if (attachmentID !== 0) {
                setloadingpagesection(true)
                await dispatch(CreatePageContentAction({
                    "PageName": sectionName,
                    "Title": pagetitle,
                    "SubTitle": pagesubtitle,
                    "Description": pagedesc
                }))
                setloadingpagesection(false)
            }
        }
        run()
    }, [attachmentID])

    //// On Finishing Create Page Section 
    useEffect(() => {
        const Run = async () => {
            if (loadingpagesection === false) {
                // console.log(createpagecontent);
                if (createpagecontent.status === 200) {
                    await setpageSectionID(createpagecontent.data.data)
                }
            }
        }
        Run()
    }, [loadingpagesection])


    useEffect(() => {
        BindSectionToImage(pageSectionID, attachmentID)
    }, [pageSectionID])

    //// Bind Page Section ID With AttachmentID
    const BindSectionToImage = async (secID, attachID) => {
        setloadingitem(true)
        await dispatch(CreatePageContentAttachAction({
            "PageContentId": secID,
            "AttachmentId": attachID
        }))
        setloadingitem(false)
    }


    //// Binding Section ID To Image ID
    useEffect(() => {
        const Run = async () => {
            if (loadingitem === false) {
                // console.log(bindsectionwithimage);
                if (bindsectionwithimage.status === 200) {
                    if (bindsectionwithimage.data && bindsectionwithimage.data.data) {
                        setpagetitle('')
                        setpagesubtitle('')
                        setpagedesc('')
                        document.getElementById("outlined-image-input").value = "";
                        // setpageImg(up)
                        setpageImgCaption('')
                        notify('تم اضافة المحتوي بنجاح', 'success')
                        setTimeout(() => {
                            window.location.pathname = "/admin/pagecontent"
                        }, 2000);
                    }
                }
                else {
                    notify('يرجي تسجيل الدخول مرة أخري لتجديد الجلسة', 'error')
                }
            }
        }
        Run()

    }, [loadingitem])

    return [pagetitle, changePageTitle, pagesubtitle, changePageSubTitle, pagedesc, changePageDesc,
        onImageChange, addSection, pageImg, pageImgCaption,
        loadingimg, loadingpagesection, loadingitem]
}


export default AddPageContentHook
