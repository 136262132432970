import { getallpagecontent, createpagecontent, getpagecontentwithid, updatepagecontent, getallpublicpagecontent } from './../Types';

const initial =
{
    getallpagecontentres: [],
    createpagecontentres: [], 
    getpagecontentwithidres : [], 
    updatepagecontentres : [], 
    getallpublicpagecontentres : []
}

const PageContentReducer = (state = initial, action) => {
    switch (action.type) {
        case getallpagecontent:
            return {
                getallpagecontentres: action.data,
                status: action.status
            }
        case createpagecontent:
            return {
                createpagecontentres: action.data,
                status: action.status
            }
        case getpagecontentwithid:
            return {
                getpagecontentwithidres: action.data,
                status: action.status
            }
        case updatepagecontent:
            return {
                updatepagecontentres: action.data,
                status: action.status
            }
        case getallpublicpagecontent:
            return {
                getallpublicpagecontentres: action.data,
                status: action.status
            }
        default:
            return state
    }

}

export default PageContentReducer;