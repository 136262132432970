import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetPublicReviewsAction } from '../../Redux/Actions/ReviewsActions';

const GetAllPublicReviewsHook = () => {

    const dispatch = useDispatch();

    const getallpublicreviews = useSelector(state => state.ReviewRed.getallpublicreviewsres)
    
    //// On Start Up Public Reviews 
    useEffect(() => {
        const call = async () => {
            await dispatch(GetPublicReviewsAction({
                "IsActiveOnly":true
            }))
        }
        call()
    }, [])


    return [getallpublicreviews]
}


export default GetAllPublicReviewsHook
