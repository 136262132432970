import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import notify from './../../CustomHooks/useNotification';
import {loginUser} from './../../Redux/Actions/LoginActions';


const LoginHook = () => {

    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [loading, setloading] = useState(null)

    const dispatch = useDispatch();
    const loggedUser = useSelector(state => state.LoginRed.loggeduser)
    const myStatus = useSelector(state => state.LoginRed.status)

    const changeemail = (e) => {
        setemail(e.target.value)
    }

    const changepassword = (e) => {
        setpassword(e.target.value)
    }

    /// Login Click Button

    const loginsubmit = (e) => {
        e.preventDefault();
        validateValues();
    }

    ///Validate Function 

    const validateValues = async () => {
        localStorage.removeItem('UserLoginToken')
        if ((email === '') || (password === '')) {
            notify('يرجي استكمال البيانات', 'warning')            
        }
        else {
            setloading(true)
            await dispatch(loginUser({
                Username: email,
                Password: password
            }))
            setloading(false)
        }
    }

    useEffect(() => {
        if (loading === false) {
            if (myStatus === 200) {                
                //console.log(loggedUser.data.data)                           
                notify('تسجيل دخول ناجح', 'success')
                setemail('')
                setpassword('')                 
                localStorage.setItem('UserLoginToken', loggedUser.data.data.token)
                setTimeout(() => {
                    window.location.pathname = "/admin/home"
                }, 1500);
            }
             else {
                //console.log(loggedUser);
                notify('بيانات خاطئة', 'error')
                /// Delete Login Token
                localStorage.removeItem('UserLoginToken')
            } 
        }
    }, [loading])


    return [email, password, loading, changeemail, changepassword, loginsubmit] 

}


export default LoginHook
