import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetContactInfoAction } from '../../Redux/Actions/ContactInfoActions';
import notify from './../useNotification';


const GetContactInfoHook = () => {

    const dispatch = useDispatch();

    const [loadingData, setloadingData] = useState(null)

    const getcontactinfo = useSelector(state => state.ContactInfoRed.getcontactinfores)

    useEffect(() => {
        const run = async () => {
            setloadingData(true)
            await dispatch(GetContactInfoAction({}));
            setloadingData(false)
        }
        run();
    }, [])

    useEffect(() => {
        const run = () => {
            if (loadingData === false) {
                //console.log(getcontactinfo);

            }
        }
        run();
    }, [loadingData])

    return [getcontactinfo]
}


export default GetContactInfoHook
