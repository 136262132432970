import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import { Col, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { MainUrl } from './../../../../Redux/Types';


const AdminServiceDetailsCard = ({ ServiceAlbum }) => {


    // const theme = useTheme()


    return (
        <Col xs='12' sm='4' md='3'>
            <Card>

                <CardMedia
                    component="img"
                    height="200"
                    style={{ objectFit: 'fill' }}
                    image={`${MainUrl}/${ServiceAlbum.image}`}
                    alt=""
                />

                <CardActions >

                    <Link to={`/admin/editservicedetails/${ServiceAlbum.id}`} style={{ textAlign: 'center', margin: 'auto' }} onClick={() => {
                        // console.log(ServiceAlbum);
                        
                    }} >
                        تعديل
                    </Link>


                 

                </CardActions>
            </Card>
        </Col>

    )

}

export default AdminServiceDetailsCard
