
import GetAllPublicPageContentWithNameHook from './../../CustomHooks/PageContent/GetAllPublicPageContentWithNameHook';
import { MainUrl } from './../../Redux/Types';

const AboutBody = () => {

    const [getallpublicpagecontent] = GetAllPublicPageContentWithNameHook('About')

    // console.log(getallpublicpagecontent);

    return (
        <>
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">
                    <h3 className='text-center fw-bold'>نشأة الشركة</h3>
                    {
                        getallpublicpagecontent && getallpublicpagecontent.data ?
                            (
                                getallpublicpagecontent.data.data.map((item, index) => {
                                    if (item.id !== 1) {
                                        return <div key={index} className="row gy-4" data-aos="fade-up">
                                            <div className="col-lg-4">
                                                <img src={`${MainUrl}/${item.image}`} className="img-fluid" style={{ borderRadius: '10px', marginTop: '30px' }} alt='' />
                                                <img src="assets/img/about-2.jpg" className="img-fluid" style={{ marginTop: '50px', borderRadius: '10px' }} alt='' />
                                            </div>

                                            <div className="col-lg-8">
                                                <div className="content ps-lg-5">
                                                    <p style={{ color: '#fc6c14', fontWeight: 'bold', lineHeight: '1.9', fontStyle: 'italic' }}>
                                                        {item.title}

                                                    </p>
                                                    <ul>
                                                        <li>
                                                            <i className="bi bi-check-circle-fill mt-3" />
                                                            <p style={{lineHeight: '2'}} className='mx-5'>{item.subTitle}</p>

                                                        </li>
                                                        <li>
                                                            <i className="bi bi-check-circle-fill mt-5" />
                                                            <p style={{lineHeight: '2'}} className='mx-5'>{item.description}
                                                            </p>

                                                        </li>                                                       
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                })
                            ) : null
                    }
                </div>
            </section>{/* End About Section */}
        </>
    )
}

export default AboutBody
