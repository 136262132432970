import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import { CardContent } from '@mui/material';
import unknown from '../../Assets/images/unknown.png'
import { Typography } from '@mui/material';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MainUrl } from './../../../Redux/Types';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useTheme } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CardActions from '@mui/material/CardActions';


const AdminServiceCard = ({ Service }) => {

    // console.log(Service);

    const theme = useTheme()

    return (
        <Col xs='12' sm='4' md='3'>
            <Card>

                <CardMedia
                    component="img"
                    height="200"                    
                    style={{objectFit: 'fill'}}
                    image={Service.galleryAlbum.length > 0 ? `${MainUrl}/${Service.galleryAlbum[0].image}` : unknown}
                    alt=""
                />

                <CardContent>
                    <Typography sx={{color: theme.palette.primary.dark}} variant="h6" className='text-center'>
                        {`${Service.title}`}
                    </Typography>
                </CardContent>

                <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Link to={`/admin/getservicedetails/${Service.id}`}>
                        <IconButton aria-label="Add" sx={{ color: '#fc6c14', fontSize: '18px', fontWeight: 'bold', textDecoration: 'underline' }}>
                            <InfoOutlinedIcon />
                        </IconButton>
                    </Link>

                    <Link to={`/admin/editserviceitem/${Service.id}`}>
                        <IconButton aria-label="Edit" sx={{ color: '#fc6c14', fontSize: '18px', fontWeight: 'bold', textDecoration: 'underline' }}>
                            <ModeEditOutlineOutlinedIcon />
                        </IconButton>
                    </Link>


                </CardActions>
            </Card>
        </Col>


    )
}

export default AdminServiceCard
