import JobsBanner from './../../Components/Utility/JobsBanner';
import JobsContainer from './../../Components/Jobs/JobsContainer';
import NavBar from './../../Components/Utility/NavBar';
import Footer from './../../Components/Utility/Footer';

const JobsPage = () => {
    return (
        <div style={{overflow: 'hidden'}}>
            <NavBar />
           <JobsBanner /> 
           <JobsContainer /> 
           <Footer />
        </div>
    )
}

export default JobsPage
