import { Button, TextField, FormLabel, Container, Stack } from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';
import { useParams } from 'react-router-dom';
import EditBrandHook from './../../../CustomHooks/Brands/EditBrandHook';
import { MainUrl } from './../../../Redux/Types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


const AdminEditBrand = () => {

    const { id } = useParams()

    const [getonebrand, brandName, changeBrandName, onImageChange, updateBrand, brandImg, brandImgCaption, loadingimg,
        loading, BrandIsActive, ChangeActive] = EditBrandHook(id)

    return (
        <Container>
            <Stack className='d-flex'>
                <h4>تعديل عنصر رقم  #{id}</h4>
            </Stack>


            <Row className='d-flex mt-5'>
                <Col md='2' style={{ alignSelf: 'center' }}>
                    <FormLabel className='fw-bold mt-2'>الصورة</FormLabel>
                </Col>
                <Col md='10'>
                    {
                        getonebrand && getonebrand.data ?
                            <label htmlFor='upload'>
                                <img
                                    src={brandImg != '' ? brandImg : `${MainUrl}/${getonebrand.data.data.image}`}
                                    alt=''
                                    style={{ cursor: 'pointer', width: '120px', heigth: '140px', borderRadius: '10px' }}
                                />
                            </label>
                            : null
                    }
                    <input style={{ opacity: '0', position: 'absolute' }} type='file' id='upload'
                        onChange={onImageChange}
                    />
                    <span style={{ margin: 'auto 20px', fontWeight: 'bold' }} dir='rtl'>
                        {brandImgCaption}
                    </span>
                </Col>
            </Row>


            {
                getonebrand && getonebrand.data ?
                    (
                        <div className='mt-4'>
                            <FormControlLabel className='text-center m-auto' control={<Checkbox checked={BrandIsActive} onChange={ChangeActive} />} label="اظهار العنصر علي السايت" style={{ fontFamily: `var(--font-default)` }} />
                        </div>
                    ) : null

            }


            <Row className='d-flex mt-3'>
                <Col md='12'>
                    {
                        loadingimg || loading ?
                            (
                                <Button disabled onClick={updateBrand}
                                    className='px-5 ms-3 d-flex m-auto my-5'
                                    variant="contained"
                                    component="label"
                                    style={{ fontFamily: `var(--font-default)`, fontWeight: 'bold' }}
                                >
                                    حفظ التعديلات
                                    <input
                                        type="submit"
                                        hidden
                                    />
                                </Button>
                            ) :
                            (
                                <Button onClick={updateBrand}
                                    className='px-5 ms-3 d-flex m-auto my-5'
                                    variant="contained"
                                    component="label"
                                    style={{ fontFamily: `var(--font-default)`, fontWeight: 'bold' }}
                                >
                                    حفظ التعديلات
                                    <input
                                        type="submit"
                                        hidden
                                    />
                                </Button>
                            )
                    }

                </Col>
            </Row>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Flip}
            />
        </Container>
    )
}


export default AdminEditBrand
