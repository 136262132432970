import { createreviewitem, getallreviews, updatereviewitem, getonereview, getallpublicreviews} from './../Types';

const initial =
{
    createreviewitemres: [],
    getallreviewsres: [],
    updatereviewitemres: [],
    getonereviewres: [],
    getallpublicreviewsres: []
}

const ReviewReducer = (state = initial, action) => {
    switch (action.type) {
        case createreviewitem:
            return {
                createreviewitemres: action.data,
                status: action.status
            }
        case getallreviews:
            return {
                getallreviewsres: action.data,
                status: action.status
            }
        case updatereviewitem:
            return {
                updatereviewitemres: action.data,
                status: action.status
            }
        case getonereview:
            return {
                getonereviewres: action.data,
                status: action.status
            }
        case getallpublicreviews:
            return {
                getallpublicreviewsres: action.data,
                status: action.status
            }
        default:
            return state
    }

}

export default ReviewReducer;