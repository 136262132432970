import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { Typography, Box, TextField, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddBrandHook from './../../../CustomHooks/Brands/AddBrandHook';

export default function AddBrandItem() {

    const theme = useTheme()

    const [, , onImageChange, addBrand, , , loadingimg, loading] = AddBrandHook();

    return (
        <div className='mb-5'>
            <Accordion sx={{ fontFamily: `var(--font-default)` }}>
                <AccordionSummary
                    expandIcon={<AddCircleOutlineOutlinedIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography sx={{ fontFamily: `var(--font-default)`, fontWeight: 'bold' }}>اضافة ماركة جديدة</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box>

                        <TextField
                            id="outlined-image-input"
                            type="file"
                            onChange={onImageChange}
                            accept=".jpg, .jpeg, .png"
                        />

                        <Box sx={{ textAlign: 'center', marginTop: 3, textTransform: 'capitalize' }}>
                            {
                                loadingimg || loading ?
                                    (
                                        <Button disabled onClick={addBrand} sx={{ fontFamily: `var(--font-default)`, backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px' }} type="" variant='contained'>اضافة ماركة جديدة</Button>
                                    ) : (
                                        <Button onClick={addBrand} sx={{ fontFamily: `var(--font-default)`, backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px' }} type="" variant='contained'>اضافة ماركة جديدة</Button>
                                    )
                            }
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>

        </div>
    );
}