import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllPublicPageContentWithNameAction } from '../../Redux/Actions/PageContentActions';

const GetAllPublicPageContentWithNameHook = (pageName) => {

    const [loading, setloading] = useState(null);

    let getallpublicpagecontent = useSelector(state => state.PageContentRed.getallpublicpagecontentres)

    const dispatch = useDispatch()

    useEffect(() => {
        const showPageDetails = async() => {
            setloading(true)
            await dispatch(GetAllPublicPageContentWithNameAction({
                "PageName": pageName,
                "IsActiveOnly": true
            }))
            //console.log(pageName);
            setloading(false)
        }
        showPageDetails()

    }, [])
    

    return [getallpublicpagecontent]

}


export default GetAllPublicPageContentWithNameHook



