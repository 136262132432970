import TeamBanner from './../../Components/Utility/TeamBanner';
import TeamContainer from './../../Components/Team/TeamContainer';
import NavBar from './../../Components/Utility/NavBar';
import Footer from './../../Components/Utility/Footer';


const TeamPage = () => {
    return (
        <div>
            <NavBar />
            <TeamBanner />
            <TeamContainer />
            <Footer />
        </div>
    )
}

export default TeamPage
