import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';
import { RootReducer } from './Reducers/RootReducer';

const myStore = createStore(RootReducer, applyMiddleware(thunk))

export default myStore;






