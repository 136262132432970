import BaseUrl from '../../API/BaseUrl';
import { createreviewitem, getallreviews, updatereviewitem, getonereview, getallpublicreviews } from './../Types';

 /// Get All Reviews
const GetAllReviewsAction =(ReviewStatus)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/Reviews/v1/GetAllReview', ReviewStatus, config);
        //console.log(res.status);
        dispatch({            
            type: getallreviews, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getallreviews, 
            data: 'The Error is' + e.response
        })
    }
}
 

/// Create New Review Item
const CreateReviewAction =(ReviewDate)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/Reviews/v1/CreateReview', ReviewDate, config);
        //console.log(res);
        dispatch({            
            type: createreviewitem, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: createreviewitem, 
            data: 'The Error is' + e.response
        })
    }
}


/// Update Review Item 
const UpdateReviewAction =(ReviewDate)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/Reviews/v1/UpdateReview', ReviewDate, config);
        //console.log(res);
        dispatch({            
            type: updatereviewitem, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: updatereviewitem, 
            data: 'The Error is' + e.response
        })
    }
}


/// Get Review Details 
const GetReviewDetailsAction =(ReviewID)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/Reviews/v1/GetReviewById', ReviewID, config);
        //console.log(res);
        dispatch({            
            type: getonereview, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getonereview, 
            data: 'The Error is' + e.response
        })
    }
}




/************* Public Active Reviews On UI */

//// Get All Reviews
const GetPublicReviewsAction =(ReviewStatus)=> async(dispatch)=>
{
    try
    {
        const res = await BaseUrl.post('/api/Reviews/v1/GetAtiveOnlyReviews', ReviewStatus);
        //console.log(res.status);
        dispatch({            
            type: getallpublicreviews, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getallpublicreviews, 
            data: 'The Error is' + e.response
        })
    }
}
 



export { CreateReviewAction, GetAllReviewsAction, UpdateReviewAction, GetReviewDetailsAction, GetPublicReviewsAction }