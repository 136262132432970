import BaseUrl from '../../API/BaseUrl';
import { createbrand, getallbrands, getallactivebrands, editbrand, getonebrand, getallpublicbrands } from '../Types';


//// Action To Create New Brand
const CreateBrandAction =(BrandData)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/Brand/v1/CreateBrand', BrandData, config);
        //console.log(res.status);
        dispatch({            
            type: createbrand, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: createbrand, 
            data: 'The Error is' + e.response
        })
    }
}


///// Action To Get All Stored Brands 
const GetAllBrandsAction =(IsActive)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/Brand/v1/GetAllBrand', IsActive , config);
        //console.log(res);
        dispatch({            
            type: getallbrands, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getallbrands, 
            data: 'The Error is' + e.response
        })
    }
}

///// Action To Get Only Active Brands
const GetActiveBrandsAction =(IsActive)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/Brand/v1/GetAtiveOnlyBrand', IsActive , config);
        //console.log(res);
        dispatch({            
            type: getallactivebrands, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getallactivebrands, 
            data: 'The Error is' + e.response
        })
    }
}

///// Action To Edit Brand Item 
const UpdateBrandAction =(BrandData)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/Brand/v1/UpdateBrand', BrandData , config);
        //console.log(res);
        dispatch({            
            type: editbrand, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: editbrand, 
            data: 'The Error is' + e.response
        })
    }
}


///// Action To Get Brand Details With ID Parameter
const GetOneBrandDetailsAction =(BrandID)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/Brand/v1/GetBrandById', BrandID , config);
        //console.log(res);
        dispatch({            
            type: getonebrand, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getonebrand, 
            data: 'The Error is' + e.response
        })
    }
}

/************* Public Active Brands On UI */

//// Get All Brands
const GetPublicBrandsAction =(BrandStatus)=> async(dispatch)=>
{
    try
    {
        const res = await BaseUrl.post('/api/Brand/v1/GetAtiveOnlyBrand', BrandStatus);
        //console.log(res);
        dispatch({            
            type: getallpublicbrands, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getallpublicbrands, 
            data: 'The Error is' + e.response
        })
    }
}



export {CreateBrandAction, GetAllBrandsAction, GetActiveBrandsAction, UpdateBrandAction, 
    GetOneBrandDetailsAction, GetPublicBrandsAction }