import { loginuser, getinfo } from './../Types';

const initial = 
{
    loggeduser : [],
    getinfores : [] ,
    status: ''
}

const LoginReducer = (state = initial , action)=>
{
    switch(action.type)
    {
        case loginuser: 
            return{
                loggeduser : action.data,
                status : action.status
        }
        case getinfo: 
            return{
                getinfores : action.data,
                status : action.status
        }
        default:
            return state
    }

}

export default LoginReducer;