import { Typography, Box, TextField, Stack, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useForm } from "react-hook-form"
import { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';
import { useParams } from 'react-router-dom';
import AddPageContentHook from './../../../CustomHooks/PageContent/AddPageContentHook';

const AddPageContent = () => {

    const { name } = useParams()

    const [pagetitle, changePageTitle, pagesubtitle, changePageSubTitle, pagedesc, changePageDesc,
        onImageChange, addSection, pageImg, pageImgCaption,
        loadingimg, loadingpagesection, loadingitem] = AddPageContentHook(name)

    // console.log(id);

    const theme = useTheme();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()


    const onSubmit = () => {
        /* console.log('Done');*/
        /*handleClick();*/

    }

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <div className='my-5'>

            <Typography className='mb-5' sx={{ color: theme.palette.primary.dark, fontWeight: 'bold', fontSize: '25px' }}>اضافة محتوي لصفحة : {name}</Typography>

            <Box
                onSubmit={handleSubmit(onSubmit)}
                component="form"
                sx=
                {{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                }}
                noValidate
                autoComplete="off"
            >
                <Stack direction='row' spacing={2} sx={{ fontSize: '16px' }} >

                    <TextField
                        sx={{ flex: 1 }}
                        label="العنوان الرئيسي"
                        variant="outlined"
                        error={Boolean(errors.title)}
                        {...register("title", { required: true, minLength: 3 })}
                        helperText={Boolean(errors.title) === true ? 'اجباري وأقل طول 3 حروف' : null}
                        value={pagetitle}
                        onChange={changePageTitle}
                    />

                    <TextField
                        sx={{ flex: 1 }}
                        label="العنوان الفرعي"
                        variant="outlined"
                        error={Boolean(errors.subtitle)}
                        {...register("subtitle", { required: true, minLength: 3 })}
                        helperText={Boolean(errors.subtitle) === true ? 'اجباري وأقل طول 3 حروف' : null}
                        value={pagesubtitle}
                        onChange={changePageSubTitle}
                    />



                </Stack>

                <Stack>

                    <TextField
                        sx={{ flex: 1 }}
                        label="الوصف"
                        variant="outlined"
                        error={Boolean(errors.desc)}
                        {...register("desc", { required: true })}
                        helperText={Boolean(errors.desc) === true ? 'اجباري' : null}
                        value={pagedesc}
                        onChange={changePageDesc}
                        multiline
                    />
                </Stack>

                <TextField
                    id="outlined-image-input"
                    type="file"
                    onChange={onImageChange}
                    accept=".jpg, .jpeg, .png"
                />


                {
                    loadingimg || loadingpagesection || loadingitem ?
                        (
                            <Button disabled onClick={addSection} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px', display: 'block' }} type="" variant='contained'>حفظ التعديلات</Button>
                        ) : <Button onClick={addSection} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px', display: 'block' }} type="" variant='contained'>حفظ التعديلات</Button>
                }


            </Box>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Flip}
            />

        </div>
    )
}

export default AddPageContent
