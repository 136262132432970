import { Box, TextField, Stack, Button, FormLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useForm } from "react-hook-form"
import 'react-toastify/dist/ReactToastify.css';
import { Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';
import { MainUrl } from './../../../Redux/Types';
import { Col, Row } from 'react-bootstrap';
import UpdateContactInfoHook from './../../../CustomHooks/Contact Info/UpdateContactInfoHook';
import GetContactInfoHook from './../../../CustomHooks/Contact Info/GetContactInfoHook';


const ContactInfo = () => {

    const theme = useTheme();

    const [getcontactinfo] = GetContactInfoHook()

    const [facebookItem, ChangeFacebook,
        instagramItem, ChangeInstagram, youtubeItem, ChangeYouTube,
        linkedinItem, ChangeLinkedIn, phone, ChangePhone, onImageChange,
        updateSocials, logoImg, loadingImg, loadingitem] = UpdateContactInfoHook()


    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()


    const onSubmit = () => {
        /* console.log('Done');*/
        /*handleClick();*/

    }

    return (
        <div className=''>

            <Typography className='mb-4' sx={{ color: theme.palette.primary.dark, fontWeight: 'bold', fontSize: '25px' }}>روابط التواصل</Typography>

            <Box
                onSubmit={handleSubmit(onSubmit)}
                component="form"
                sx=
                {{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4
                }}
                noValidate
                autoComplete="off"
            >

                {
                    getcontactinfo && getcontactinfo.data ?
                        (
                            <TextField
                                id="filled-basic"
                                variant="outlined"
                                label='رابط الفيس بوك'
                                error={Boolean(errors.facebook)}
                                {...register("facebook", { required: true, minLength: 5 })}
                                helperText={Boolean(errors.facebook) === true ? 'اجباري وأقل طول 5 حروف' : null}
                                value={facebookItem}
                                onChange={ChangeFacebook}
                            />
                        ) : (
                            <TextField
                                id="filled-basic"
                                variant="outlined"
                                label='رابط الفيس بوك'
                                error={Boolean(errors.facebook)}
                                {...register("facebook", { required: true, minLength: 5 })}
                                helperText={Boolean(errors.facebook) === true ? 'اجباري وأقل طول 5 حروف' : null}
                                value=''
                            />
                        )
                }
                {
                    getcontactinfo && getcontactinfo.data ?
                        (
                            <TextField
                                id="filled-basic"
                                variant="outlined"
                                label='رابط انستجرام'
                                error={Boolean(errors.instagram)}
                                {...register("instagram", { required: true, minLength: 5 })}
                                helperText={Boolean(errors.instagram) === true ? 'اجباري وأقل طول 5 حروف' : null}
                                value={instagramItem}
                                onChange={ChangeInstagram}
                            />
                        ) : (
                            <TextField
                                id="filled-basic"
                                variant="outlined"
                                label='رابط انستجرام'
                                error={Boolean(errors.instagram)}
                                {...register("instagram", { required: true, minLength: 5 })}
                                helperText={Boolean(errors.instagram) === true ? 'اجباري وأقل طول 5 حروف' : null}
                                value=''
                            />
                        )
                }

                {
                    getcontactinfo && getcontactinfo.data ?
                        (
                            <TextField
                                id="filled-basic"
                                variant="outlined"
                                label='رابط لينكد ان'
                                error={Boolean(errors.linkedin)}
                                {...register("linkedin", { required: true, minLength: 5 })}
                                helperText={Boolean(errors.linkedin) === true ? 'اجباري وأقل طول 5 حروف' : null}
                                value={linkedinItem}
                                onChange={ChangeLinkedIn}
                            />
                        ) : (
                            <TextField
                                id="filled-basic"
                                variant="outlined"
                                label='رابط لينكد ان'
                                error={Boolean(errors.linkedin)}
                                {...register("linkedin", { required: true, minLength: 5 })}
                                helperText={Boolean(errors.linkedin) === true ? 'اجباري وأقل طول 5 حروف' : null}
                                value=''
                            />
                        )
                }

                {
                    getcontactinfo && getcontactinfo.data ?
                        (
                            <TextField
                                id="filled-basic"
                                variant="outlined"
                                label='رابط اليوتيوب'
                                error={Boolean(errors.youtube)}
                                {...register("youtube", { required: true, minLength: 5 })}
                                helperText={Boolean(errors.youtube) === true ? 'اجباري وأقل طول 5 حروف' : null}
                                value={youtubeItem}
                                onChange={ChangeYouTube}
                            />
                        ) : (
                            <TextField
                                id="filled-basic"
                                variant="outlined"
                                label='رابط انستجرام'
                                error={Boolean(errors.youtube)}
                                {...register("youtube", { required: true, minLength: 5 })}
                                helperText={Boolean(errors.youtube) === true ? 'اجباري وأقل طول 5 حروف' : null}
                                value=''
                            />
                        )
                }

                {
                    getcontactinfo && getcontactinfo.data ?
                        (
                            <TextField
                                id="filled-basic"
                                variant="outlined"
                                label='رقم التواصل'
                                error={Boolean(errors.phone)}
                                {...register("phone", { required: true, minLength: 5 })}
                                helperText={Boolean(errors.phone) === true ? 'اجباري وأقل طول 5 حروف' : null}
                                value={phone}
                                onChange={ChangePhone}
                            />
                        ) : (
                            <TextField
                                id="filled-basic"
                                variant="outlined"
                                label='رقم التواصل'
                                error={Boolean(errors.phone)}
                                {...register("phone", { required: true, minLength: 5 })}
                                helperText={Boolean(errors.phone) === true ? 'اجباري وأقل طول 5 حروف' : null}
                                value=''
                            />
                        )
                }


                <Row className='d-flex mt-5'>
                    <Col md='1' style={{ alignSelf: 'center' }}>
                        <FormLabel className='fw-bold mt-2'>اللوجو</FormLabel>
                    </Col>
                    <Col md='11'>
                        {
                            getcontactinfo && getcontactinfo.data ?
                                <label htmlFor='upload'>
                                    <img
                                        src={logoImg != '' ? logoImg : `${MainUrl}/${getcontactinfo.data.data.logo}`}
                                        alt=''
                                        style={{ cursor: 'pointer', width: '120px', heigth: '140px', borderRadius: '10px' }}
                                    />
                                </label>
                                : null
                        }
                        <input style={{ opacity: '0', position: 'absolute' }} type='file' id='upload'
                            onChange={onImageChange}
                        />
                        <span style={{ margin: 'auto 20px', fontWeight: 'bold' }} dir='rtl'>
                            {/* {brandImgCaption} */}
                        </span>
                    </Col>
                </Row>
                {
                    loadingImg || loadingitem ?
                        (
                            <Button disabled onClick={updateSocials} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px', display: 'block' }} type="submit" variant='contained'>حفظ التعديلات</Button>
                        ) : (
                            <Button onClick={updateSocials} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px', display: 'block' }} type="submit" variant='contained'>حفظ التعديلات</Button>
                        )
                }

            </Box>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Flip}
            />

        </div>
    )
}

export default ContactInfo
