import { Link } from 'react-router-dom';


const CallToAction = () => {
    return (
        <>
            <section id="call-to-action" className="call-to-action mt-4">
                <div className="container" data-aos="fade-up">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                            <h3>معنا اكتشف كل جديد في عالم الدفع الالكتروني</h3>
                            <Link className="cta-btn mt-4" to="/joinus">انضم إلينا</Link>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default CallToAction
