import BaseUrl from '../../API/BaseUrl';
import { getallgallery, creategalleryitem, getonegallery, updategalleryitem, createalbumitem, getallpublicgallery, 
    updatealbumitem } from './../Types';


/// Get All Galleries
const GetAllGalleryAction =(GalleryData)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/Gallery/v1/GetAllGallery', GalleryData, config);
        //console.log(res.status);
        dispatch({            
            type: getallgallery, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getallgallery, 
            data: 'The Error is' + e.response
        })
    }
}


/// Create New Gallery Item
const CreateGalleryAction =(GalleryData2)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/Gallery/v1/CreateGallery', GalleryData2, config);
        //console.log(res);
        dispatch({            
            type: creategalleryitem, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: creategalleryitem, 
            data: 'The Error is' + e.response
        })
    }
}

/// Get Details For One GalleryItem
const GetGalleryItemDetailsAction =(GalleryID)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/Gallery/v1/GetGalleryById', GalleryID, config);
        //console.log(res);
        dispatch({            
            type: getonegallery, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getonegallery, 
            data: 'The Error is' + e.response
        })
    }
}



/// Update Gallery Item
const UpdateGalleryItemAction =(GalleryData3)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/Gallery/v1/UpdateGallery', GalleryData3, config);
        //console.log(res);
        dispatch({            
            type: updategalleryitem, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: updategalleryitem, 
            data: 'The Error is' + e.response
        })
    }
}


/************* Public Active Gallery On UI */

/// Get All Galleries
const GetPublicGalleryAction =(GalleryData)=> async(dispatch)=>
{
    try
    {
        const res = await BaseUrl.post('/api/Gallery/v1/GetAtiveOnlyGallery', GalleryData);
        //console.log(res.status);
        dispatch({            
            type: getallpublicgallery, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getallpublicgallery, 
            data: 'The Error is' + e.response
        })
    }
}



/* Gallery Album Actions **********/


/// Create Gallery Album Item
const CreateAlbumItemAction =(GalleryInfo)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/GalleryAlbum/v1/CreateGalleryAlbum', GalleryInfo, config);
        //console.log(res);
        dispatch({            
            type: createalbumitem, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: createalbumitem, 
            data: 'The Error is' + e.response
        })
    }
}


/// Update Gallery Album Item
const UpdateGalleryAlbumAction =(GalleryInfo)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/GalleryAlbum/v1/UpdateGalleryAlbum', GalleryInfo, config);
        //console.log(res);
        dispatch({            
            type: updatealbumitem, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: updatealbumitem, 
            data: 'The Error is' + e.response
        })
    }
}




export { GetAllGalleryAction, CreateGalleryAction, GetGalleryItemDetailsAction
    , UpdateGalleryItemAction,GetPublicGalleryAction, CreateAlbumItemAction, UpdateGalleryAlbumAction }