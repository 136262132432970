import Form from 'react-bootstrap/Form';
import { Container, Row, Col } from 'react-bootstrap';
import JoinUsHook from './../../CustomHooks/JoinUs/JoinUsHook';
import { MDBFile } from 'mdb-react-ui-kit';
import { Button, Stack, Box } from '@mui/material';
import JoinSVG from './../Utility/JoinSVG';

const JoinUSForm = () => {

    const [firstname, firstNameChange, lastname, lastNameChange, email, emailchange, username, userNameChange,
        password, passwordChange, mobile, mobilechange, nationalid, nationalidChange, shopName, shopNameChange, CreateNewPayUser
        , ApplListChangeValue, onImageChange, loadingimg, loadingUser] = JoinUsHook()

    return (
        <Box>
            <Row className='text-center mt-3 pt-4'>
                <h2 style={{ color: '#fc6c14' }}>يشرفنا العمل معك</h2>
            </Row>

            <Stack direction='row' sx={{ justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: { md: 2, xs: 5 } }} className='mt-3 fw-bold' style={{ fontFamily: 'Roboto' }}>

                <Form className='fw-bold col-lg-5 col-12'>

                    <Form.Group className="my-3 d-flex justify-content-center align-items-center flex-wrap">

                        <Col sm='12'>
                            <Form.Control value={firstname} onChange={firstNameChange} type="text" placeholder="الاسم الأول" className='py-2 fs-6 fw-bold' />
                        </Col>
                    </Form.Group>

                    <Form.Group className="my-3 d-flex justify-content-center align-items-center flex-wrap">
                        <Col sm='12'>
                            <Form.Control value={lastname} onChange={lastNameChange} type="text" placeholder="الاسم الأخير" className='py-2 fs-6 fw-bold' />
                        </Col>
                    </Form.Group>

                    <Form.Group className="my-3 d-flex justify-content-center align-items-center flex-wrap">
                        <Col sm='12'>
                            <Form.Control value={email} onChange={emailchange} type="email" placeholder="البريد الالكتروني" className='py-2 fs-6 fw-bold' />
                        </Col>
                    </Form.Group>

                    <Form.Group className="my-3 d-flex justify-content-center align-items-center flex-wrap">
                        <Col sm='12'>
                            <Form.Control value={username} onChange={userNameChange} type="text" placeholder="اسم المستخدم" className='py-2 fs-6 fw-bold' />
                        </Col>
                    </Form.Group>

                    <Form.Group className="my-3 d-flex justify-content-center align-items-center flex-wrap">
                        <Col sm='12'>
                            <Form.Control value={password} onChange={passwordChange} type="password" placeholder="كلمة المرور" className='py-2 fs-6 fw-bold' />
                        </Col>
                    </Form.Group>

                    <Form.Group className="my-3 d-flex justify-content-center align-items-center flex-wrap">
                        <Col sm='12'>
                            <Form.Control value={mobile} onChange={mobilechange} type="number" placeholder="الهاتف الشخصي" className='py-2 fs-6 fw-bold' />
                        </Col>
                    </Form.Group>

                    <Form.Group className="my-3 d-flex justify-content-center align-items-center flex-wrap">
                        <Col sm='12'>
                            <Form.Control value={nationalid} onChange={nationalidChange} type="number" placeholder="رقم البطاقة" className='py-2 fs-6 fw-bold' />
                        </Col>
                    </Form.Group>

                    <Form.Group className="my-3 d-flex justify-content-center align-items-center flex-wrap">
                        <Col sm='12'>
                            <Form.Control value={shopName} onChange={shopNameChange} type="text" placeholder="اسم المحل" className='py-2 fs-6 fw-bold' />
                        </Col>
                    </Form.Group>

                    <Form.Group className="my-4 d-flex justify-content-center align-items-center flex-wrap">

                        <Col sm='4'>
                            <Form.Label style={{ color: '#1c88ba' }}>هل تريد العمل مع</Form.Label>
                        </Col>

                        <Col sm='8'>
                            <Form.Select onChange={ApplListChangeValue} className='py-2 fs-6 fw-bold'>
                                <option className='my-5' value="1">التجار</option>
                                <option className='my-2' value="2">المناديب</option>
                                <option className='my-2' value="3">الأفراد</option>
                            </Form.Select>

                        </Col>

                    </Form.Group>

                    <Form.Group className="my-4 d-flex justify-content-center align-items-center flex-wrap">
                        <Col sm='4'>
                            <Form.Label style={{ color: '#1c88ba' }}>صورة البطاقة</Form.Label>
                        </Col>
                        <Col sm='8'>

                            <MDBFile accept=".png" id='ImageInput' onChange={onImageChange} multiple />
                        </Col>

                    </Form.Group>



                    {/* <Button onClick={CreateNewPayUser} className='d-block w-100 mt-5 mb-3 text-center' variant="primary">
                        تقديم
                    </Button> */}

                    {
                        loadingimg || loadingUser ?
                            (
                                <Button disabled variant='contained' className='fs-5 joinButton mt-4 w-100' onClick={CreateNewPayUser} type="">ارسال البيانات</Button>
                            ) : (
                                <Button variant='contained' className='fs-5 joinButton mt-4 w-100' onClick={CreateNewPayUser} type="">ارسال البيانات</Button>
                            )
                    }
                </Form>

                <JoinSVG />

            </Stack>
        </Box>
    );
}

export default JoinUSForm;