import EditGalleryItemHook from './../../../CustomHooks/Gallery/EditGalleryItemHook';
import * as React from 'react';
import { Stack, Box, TextField, Button, Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { Slide } from 'react-toastify';
import { Row } from 'react-bootstrap';
import { useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useParams } from 'react-router-dom';


const AdminEditService = () => {

    const { id } = useParams()

    const [getonegallery, GalleryName, ChangeGalleryTitle,
        GallerySubTitle, ChangeGallerySubTitle, GalleryDesc, ChangeGalleryDesc, GalleryVideoLink,
        ChangeGalleryVideoLink, GalleryIsActive, ChangeActive, GalleryIsMain, ChangeGalleryMainOrNot,
        UpdateGalleryItem, loadinggalUpdate] = EditGalleryItemHook(id)

    const theme = useTheme()

    return (
        <>

            <Typography className='mb-5' sx={{ color: theme.palette.primary.dark, fontWeight: 'bold', fontSize: '25px' }}>تعديل خدمة رقم : {id}</Typography>

            {
                getonegallery && getonegallery.data ?
                    (
                        <Box
                            component="form"
                            sx=
                            {{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 4,
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Stack direction='row' spacing={2} sx={{ fontSize: '16px' }} >



                                <TextField
                                    sx={{ flex: 1 }}
                                    label="عنوان الخدمة"
                                    variant="outlined"
                                    value={GalleryName}
                                    onChange={ChangeGalleryTitle}
                                />

                                <TextField
                                    sx={{ flex: 1 }}
                                    label="العنوان الفرعي"
                                    variant="outlined"
                                    value={GallerySubTitle}
                                    onChange={ChangeGallerySubTitle}
                                />

                            </Stack>

                            <Stack>

                                <TextField
                                    sx={{ flex: 1 }}
                                    label="وصف الخدمة"
                                    variant="outlined"
                                    value={GalleryDesc}
                                    onChange={ChangeGalleryDesc}
                                    multiline
                                />
                            </Stack>

                            <div className='mt-4'>
                                <FormControlLabel className='text-center m-auto' control={<Checkbox checked={GalleryIsMain} onChange={ChangeGalleryMainOrNot} />} label="اظهار الخدمة في الصفحة الرئيسية" style={{ fontFamily: `var(--font-default)` }} />
                            </div>

                            <div className='mt-1'>
                                <FormControlLabel className='text-center m-auto' control={<Checkbox checked={GalleryIsActive} onChange={ChangeActive} />} label="اظهار الخدمة علي السايت" style={{ fontFamily: `var(--font-default)` }} />
                            </div>  

                            {
                                loadinggalUpdate ?
                                    (
                                        <Button className='mt-2 mb-3' disabled onClick={UpdateGalleryItem} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px', display: 'block' }} type="" variant='contained'>حفظ التعديلات</Button>
                                    ) : <Button className='mt-2 mb-3' onClick={UpdateGalleryItem} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px', display: 'block' }} type="" variant='contained'>حفظ التعديلات</Button>
                            }


                        </Box>

                    ) : null
            }

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Slide}
            />

        </>
    )
}

export default AdminEditService
