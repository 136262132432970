import { Link } from "react-router-dom"

const ContactBanner = () => {
    return (
        <>
            {/* ======= Breadcrumbs ======= */}
            <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/contact-header.jpg")', minHeight: '60vh' }}>
                <div className="container position-relative d-flex flex-column align-items-center">
                    <h2 className='my-4'>تواصل معنا</h2>
                    <ol>
                        <li style={{ fontSize: '18px' }}><Link to="/">الرئيسية</Link></li>
                        <li style={{ fontSize: '18px' }}> تواصل معنا</li>
                    </ol>
                </div>
            </div>{/* End Breadcrumbs */}
        </>
    )
}

export default ContactBanner
