import AccountInfo from './../../../Components/Admin/AccountInfo/AccountInfo';

const AccountInfoPage = () => {
    return (
        <div>
            <AccountInfo />
        </div>
    )
}

export default AccountInfoPage
