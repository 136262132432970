import { Button, Form, Modal } from 'react-bootstrap';
import GetAllPublicPageContentWithNameHook from './../../CustomHooks/PageContent/GetAllPublicPageContentWithNameHook';
import { MainUrl } from './../../Redux/Types';
import { useState, useEffect } from 'react';
import CreateMessageHook from './../../CustomHooks/Contact/CreateMessageHook';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';


const JobsContainer = () => {

    const [getallpublicpagecontent] = GetAllPublicPageContentWithNameHook('Careers')

    const [Name, changeName, Phone, changePhone, Message, changeMessage, postmessage, ModalClosed] = CreateMessageHook()

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setShow(false)
    }, [ModalClosed])

    // console.log(getallpublicpagecontent);

    return (
        <>
            <div className='mt-5' data-aos="fade-up" data-aos-delay={200}>
                <div className="row gy-5 posts-list p-5">

                    {
                        getallpublicpagecontent && getallpublicpagecontent.data ?
                            (
                                getallpublicpagecontent.data.data.map((item, index) => {
                                    return <div key={index} className="col-lg-4 col-md-6">
                                        <article className="d-flex flex-column">
                                            <div className="post-img">
                                                <img style={{ borderRadius: '20px', width: '100%' }} src={`${MainUrl}/${item.image}`} alt='' className="img-fluid" />
                                            </div>
                                            <h4 style={{ color: '#fc6c14' }} className='text-center mt-4'>
                                                {item.title}
                                            </h4>
                                            <div className="content mt-3">
                                                <p className='fw-bold' style={{ height: 'auto' }}>
                                                    {item.description}
                                                </p>
                                            </div>
                                            <Button onClick={handleShow} variant='warning' className='py-2 px-5 fw-bold w-sm-50 mt-4 mt-sm-1 m-auto'>سجل الآن</Button>
                                        </article>
                                    </div>
                                })
                            ) : null
                    }


                </div>

            </div>{/* End blog posts list */}

            <>
                <Modal centered style={{ fontWeight: 'bold' }} show={show} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>التسجيل للوظيفة</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>الاسم</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    autoFocus
                                    value={Name}
                                    onChange={changeName}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>رقم الهاتف</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder=""
                                    value={Phone}
                                    onChange={changePhone}
                                />
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                            >
                                <Form.Label>ملاحظات</Form.Label>
                                <Form.Control as="textarea" rows={3}
                                    value={Message}
                                    onChange={changeMessage} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            اغلاق
                        </Button>
                        <Button variant="primary" onClick={() => {
                            postmessage()                                                        
                        }}>
                            ارسال
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>


            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Flip}
            />
        </>


    )
}

export default JobsContainer
