import AdminServiceDetails from '../../../../Components/Admin/AdminServices/AdminServiceDetails/AdminServiceDetails';

const AdminServiceDetailsPage = () => {
    return (
        <div>
            <AdminServiceDetails />
        </div>
    )
}



export default AdminServiceDetailsPage
