import BaseUrl from '../../API/BaseUrl';
import { getaccountinfo, updateadminpw} from './../Types';

const GetAccountInfoAction =()=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.get('/api/Account/v1/GetInfo', config);
        //console.log(res.status);
        dispatch({            
            type: getaccountinfo, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getaccountinfo, 
            data: 'The Error is' + e.response
        })
    }
}


const UpdateAdminPasswordAction =(PWData)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/Account/v1/UpdateUserPassword', PWData ,config);
        // console.log(res);
        dispatch({            
            type: updateadminpw, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: updateadminpw, 
            data: 'The Error is' + e.response
        })
    }
}


export {GetAccountInfoAction, UpdateAdminPasswordAction}