import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetPublicBrandsAction } from '../../Redux/Actions/BrandsAction';

const GetAllPublicBrandsHook = () => {

    const dispatch = useDispatch();

    const getallpublicbrands = useSelector(state => state.BrandRed.getallpublicbrandsres)
    
    //// On Start Up Public Reviews 
    useEffect(() => {
        const call = async () => {
            await dispatch(GetPublicBrandsAction({
                "IsActiveOnly":true
            }))
        }
        call()
    }, [])


    return [getallpublicbrands]
}


export default GetAllPublicBrandsHook
