
import { MainUrl } from './../../Redux/Types';
import { Link } from 'react-router-dom';

const UIServiceCard = ({Serviceitem}) => {
    return (
        <>
            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                {
                    Serviceitem.galleryAlbum && Serviceitem.galleryAlbum.length > 0 ?
                        (
                            Serviceitem.galleryAlbum.slice(0, 1).map((item, index) => {
                                return <img key={index} src={`${MainUrl}/${item.image}`} style={{ width: '100%', height: '300px', objectFit: 'fill' }} alt='' />
                            })
                        ) : <img src="assets/img/portfolio/app-1.jpg" style={{ width: '100%' }} alt='' />
                }
                <div className="portfolio-info">
                    <h4>{Serviceitem.title}</h4>
                    <p className='mx-3'>{Serviceitem.subTitle}</p>
                    <Link to={`/servicedetails/${Serviceitem.id}`} title="مزيد من التفاصيل" className="details-link"><i className="bi bi-link-45deg" /></Link>

                </div>
            </div>
        </>
    )
}

export default UIServiceCard
