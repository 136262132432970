import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAccountInfoAction } from '../../Redux/Actions/AccountInfoActions';

const AccountInfoHook = () => {

    const dispatch = useDispatch();

    const getaccountinfo = useSelector(state => state.AccountInfoRed.getaccountinfores)
    
    //// On Start Up Public Reviews 
    useEffect(() => {
        const call = async () => {
            await dispatch(GetAccountInfoAction())
        }
        call()
    }, [])


    return [getaccountinfo]
}


export default AccountInfoHook
