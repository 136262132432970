import BaseUrl from '../../API/BaseUrl';
import { getallpagecontent, createpagecontent, getpagecontentwithid, updatepagecontent, getallpublicpagecontent } from './../Types';

const GetAllPageContentAction =(PageData)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/PageContent/v1/GetAllPageContent', PageData, config);
        //console.log(res.status);
        dispatch({            
            type: getallpagecontent, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getallpagecontent, 
            data: 'The Error is' + e.response
        })
    }
}

///// Get All Page Content On UI Without Token

const GetAllPublicPageContentWithNameAction =(PageData)=> async(dispatch)=>
{
    try
    {
        const res = await BaseUrl.post('/api/PageContent/v1/GetAtiveOnlyPageContent', PageData);
        //console.log(res.status);
        dispatch({            
            type: getallpublicpagecontent, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getallpublicpagecontent, 
            data: 'The Error is' + e.response
        })
    }
}


/// Add New Page Content 
const CreatePageContentAction =(PageData)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/PageContent/v1/CreatePageContent', PageData, config);
        //console.log(res.status);
        dispatch({            
            type: createpagecontent, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: createpagecontent, 
            data: 'The Error is' + e.response
        })
    }
}


/// Get Page Content Details With ID Parameter
const GetPageContentActionByID =(PageSectionID)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/PageContent/v1/GetPageContentById', PageSectionID, config);
        //console.log(res.status);
        dispatch({            
            type: getpagecontentwithid, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getpagecontentwithid, 
            data: 'The Error is' + e.response
        })
    }
}


/// Edit In Page Content Section
const UpdatePageContentAction =(pageData)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/PageContent/v1/UpdatePageContent', pageData, config);
        //console.log(res.status);
        dispatch({            
            type: updatepagecontent, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: updatepagecontent, 
            data: 'The Error is' + e.response
        })
    }
}



export { GetAllPageContentAction , CreatePageContentAction, GetPageContentActionByID,  
    UpdatePageContentAction, GetAllPublicPageContentWithNameAction}