import NavBar from './../../Components/Utility/NavBar';
import Footer from './../../Components/Utility/Footer';
import DepositBanner from './../../Components/Utility/DepositBanner';
import DepositsContainer from './../../Components/Deposits/DepositsContainer';

const DepositPage = () => {
    return (
        <div>
            <NavBar />
           <DepositBanner /> 
           <DepositsContainer /> 
           <Footer />
        </div>
    )
}

export default DepositPage
