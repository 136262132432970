import logo from '../../Components/Assets/images/Logo.png'
import GetPublicContactInfoHook from './../../CustomHooks/Contact Info/GetPublicContactInfoHook';
import { MainUrl } from './../../Redux/Types';
import { Link } from 'react-router-dom';

const Footer = () => {

    const [getpubliccontactinfo] = GetPublicContactInfoHook()

    return (
        <>
            {/* ======= Footer ======= */}
            <footer id="footer" className="footer fw-bold text-center mt-4" >
                <div className="footer-content">
                    <div className="container">
                        <div className="row gy-4 g-5">
                            <div className="col-lg-6 col-12 footer-info">
                                <div>
                                    {
                                        getpubliccontactinfo && getpubliccontactinfo.data ?
                                            (
                                                <img className='imglogo' src={`${MainUrl}/${getpubliccontactinfo.data.data.logo}`} alt="" />
                                            ) : <img className='imglogo' src={logo} alt="" />
                                    }
                                </div>
                                <div>
                                    <p style={{ lineHeight: '2.7' }}>لدعم نمو اعمالك ونشاطك التجارى قمنا بتصميم حلول دفع و خدمات لارسال واستقبال المدفوعات بشكل أسرع وآمن وأسهل لعملائك</p>
                                    {
                                        getpubliccontactinfo && getpubliccontactinfo.data ? 
                                            (
                                                <div className="social-links d-flex mt-3 text-center d-flex justify-content-center">
                                                    <Link to={getpubliccontactinfo.data.data.facebook} target='_blank' className="facebook"><i className="bi bi-facebook" /></Link>
                                                    <Link to={getpubliccontactinfo.data.data.instagram} target='_blank' className="twitter"><i className="bi bi-instagram" /></Link>
                                                    <Link to={getpubliccontactinfo.data.data.youtube} target='_blank' className="instagram"><i className="bi bi-youtube" /></Link>
                                                    <Link to={getpubliccontactinfo.data.data.linkedin} target='_blank' className="linkedin"><i className="bi bi-linkedin" /></Link>
                                                </div>
                                            ) : null
                                    }

                                </div>
                            </div>
                            <div className="pt-3 col-lg-3 col-sm-6 col-12 footer-links">
                                <h4 style={{ color: '#fc6c14' }}>روابط سريعة</h4>
                                <div className='text-md-end my-2'><i className="bi bi-dash" /> <Link style={{ color: '#FFF' }} to='/'>الرئيسية</Link></div>
                                <div className='text-md-end'><i className="bi bi-dash" /> <Link style={{ color: '#FFF' }} to="/about">نبذة عنا</Link></div>
                                <div className='text-md-end my-2'><i className="bi bi-dash" /> <Link style={{ color: '#FFF' }} to="/services">خدماتنا</Link></div>
                                <div className='text-md-end my-2'><i className="bi bi-dash" /> <Link style={{ color: '#FFF' }} to="/deposits">الايداعات</Link></div>
                                <div className='text-md-end'><i className="bi bi-dash" /> <Link style={{ color: '#FFF' }} to="/team">فريق موجة</Link></div>
                                <div className='text-md-end my-2'><i className="bi bi-dash" /> <Link style={{ color: '#FFF' }} to="/joinus">انضم إلينا</Link></div>
                                <div className='text-md-end my-2'><i className="bi bi-dash" /> <Link style={{ color: '#FFF' }} to="/jobs">الوظائف</Link></div>
                            </div>
                            <div className="pt-3 col-lg-3 col-sm-6 col-12 footer-contact text-center">
                                <h4 style={{ color: '#fc6c14' }}>تواصل معنا</h4>
                                <p>
                                    العنوان : 1 شارع الثورة الهرم ، الجيزة
                                </p>
                                <p> الدعم :
                                    {
                                        getpubliccontactinfo && getpubliccontactinfo.data ?
                                            (
                                                getpubliccontactinfo.data.data.phone
                                            ) : null

                                    } </p>
                                <p className='mt-3'> البريد الالكتروني: info@mogapayment.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-legal">
                    <div className="copyright">
                        All Rights Reserved To <span style={{ color: '#fc6c14' }}>MOGA</span>©2024 , Made By <span style={{ color: '#fc6c14' }}>MOGA Team</span>
                    </div>
                </div>
            </footer>{/* End Footer */} {/* End Footer */}


        </>
    )
}

export default Footer
