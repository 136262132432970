import EditReviewComponent from './../../../Components/Admin/Reviews/EditReviewComponent';

const AdminEditReviewPage = () => {


    return (
        <div>

            <EditReviewComponent />
        </div>
    )
}

export default AdminEditReviewPage
