
import GetAllPublicPageContentWithNameHook from './../../CustomHooks/PageContent/GetAllPublicPageContentWithNameHook';
import { MainUrl } from './../../Redux/Types';
import { Link } from 'react-router-dom';

const TeamContainer = () => {

    const [getallpublicpagecontent] = GetAllPublicPageContentWithNameHook('Team')


    return (
        <>
            {/* ======= Team Section ======= */}
            <section id="team" className="team">
                <div className="container" data-aos="fade-up">
                    <div className="section-header">
                        <h2>صناع النجاح</h2>
                    </div>
                    <div className="row gy-4">
                        {
                            getallpublicpagecontent && getallpublicpagecontent.data ?
                                (
                                    getallpublicpagecontent.data.data.map((item, index) => {
                                        return <div key={index} className="col-lg-3 col-sm-6 col-12" data-aos="fade-up" data-aos-delay={300}>
                                            <div className="team-member">
                                                <div className="member-img" style={{width: '250px'}}>
                                                    <img src={`${MainUrl}/${item.image}`} className="team img-fluid" alt='' style={{ borderRadius: '10px', height: '300px', minWidth: '100%' }} />
                                                    <div className="social">
                                                        <Link to='https://www.youtube.com/' target='_blank'><i className="bi bi-youtube" /></Link>
                                                        <Link to='https://web.facebook.com/mogapay.org' target='_blank'><i className="bi bi-facebook" /></Link>
                                                        <Link to='https://www.instagram.com/' target='_blank'><i className="bi bi-instagram" /></Link>
                                                        <Link to='https://www.linkedin.com/' target='_blank'><i className="bi bi-linkedin" /></Link>
                                                    </div>
                                                </div>
                                                <div className='py-3 d-flex justify-content-center flex-column align-items-start px-4'>
                                                    <h4 style={{ color: '#fc6c14' }}>{item.title}</h4>
                                                    <h6>{item.subTitle}</h6>
                                                </div>
                                            </div>

                                        </div>


                                    })
                                ) : null
                        }
                    </div>
                </div>
            </section>

        </>
    )
}

export default TeamContainer
