import { Link } from 'react-router-dom';

const JobsBanner = () => {
    return (
        <>
            {/* ======= Breadcrumbs ======= */}
            <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/blog-header.jpg")', minHeight: '60vh' }}>
                <div className="container position-relative d-flex flex-column align-items-center">
                    <h2 className='my-4'>وظائف موجة</h2>
                    <ol>
                        <li><Link to="/">الرئيسية</Link></li>
                        <li> الوظائف </li>
                    </ol>
                </div>
            </div>{/* End Breadcrumbs */}

        </>
    )
}

export default JobsBanner
