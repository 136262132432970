import Hero from './../../Components/Home/Hero';
import WhyChooseUs from './../../Components/Home/WhyChooseUs';
import HomeReviews from './../../Components/Home/HomeReviews';
import ServicesContainer from './../../Components/Services/ServicesContainer';
import HomeBrands from '../../Components/Home/HomeBrands';
import CallToAction from './../../Components/Home/CallToAction';
import NavBar from './../../Components/Utility/NavBar';
import Footer from './../../Components/Utility/Footer';
import GetAllPublicPageContentWithNameHook from './../../CustomHooks/PageContent/GetAllPublicPageContentWithNameHook';

const HomePage = () => {

    const [getallpublicpagecontent] = GetAllPublicPageContentWithNameHook('')
    
    return (
        <div style={{overflow: 'hidden'}}>

            <NavBar />
            <Hero getallpublicpagecontent = {getallpublicpagecontent} />
            <WhyChooseUs getallpublicpagecontent = {getallpublicpagecontent} />
            <div className='section-header mt-5'>
                <h2 className='mb-1'>خدمات موجة</h2>
                <ServicesContainer Active={true} Main={true} />
            </div>
            <HomeReviews />
            <CallToAction />
            <HomeBrands />

            <Footer />
        </div>
    )
}

export default HomePage
