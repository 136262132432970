import { toast } from 'react-toastify';

    const notify = (msg, type) => {

          /// Handling Toasts
            switch(type)
            {
                case 'success' : 
                    toast.success(msg)
                    break;
                case 'warn':
                    toast.warn(msg)
                    break;
                default : 
                    toast.error(msg)
                    break;
            }
}

export default notify
