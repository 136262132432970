import { getallgallery, creategalleryitem, getonegallery, updategalleryitem, createalbumitem, getallpublicgallery, updatealbumitem } from './../Types';

const initial =
{
    creategalleryitemres: [],
    getallgalleryres: [], 
    getonegalleryres : [],
    updategalleryitemres : [],
    createalbumitemres : [], 
    getallpublicgalleryres : [], 
    updatealbumitemres : []
}

const GalleryReducer = (state = initial, action) => {
    switch (action.type) {
        case creategalleryitem:
            return {
                creategalleryitemres: action.data,
                status: action.status
            }
        case getallgallery:
            return {
                getallgalleryres: action.data,
                status: action.status
            }
        case getonegallery:
            return {
                getonegalleryres: action.data,
                status: action.status
            }
        case updategalleryitem:
            return {
                updategalleryitemres: action.data,
                status: action.status
            }
        case createalbumitem:
            return {
                createalbumitemres: action.data,
                status: action.status
            }
        case getallpublicgallery:
            return {
                getallpublicgalleryres: action.data,
                status: action.status
            }
        case updatealbumitem:
            return {
                updatealbumitemres: action.data,
                status: action.status
            }
        default:
            return state
    }

}

export default GalleryReducer;