import { motion } from "framer-motion";
import { MainUrl } from './../../Redux/Types';

const WhyChooseUs = ({ getallpublicpagecontent }) => {

    return (
        <>
            {/* ======= Why Choose Us Section ======= */}
            <section id="why-us" className="why-us">
                {
                    getallpublicpagecontent && getallpublicpagecontent.data ?
                        (
                            getallpublicpagecontent.data.data.slice(0,1).map((item, index) => {
                                if (item.pageName === 'About') {
                                    return (
                                        <div key = {index} className="container" data-aos="fade-up">
                                            <div className="section-header">
                                                <h2>{item.title}</h2>
                                            </div>

                                            <div className="row g-5" data-aos="fade-up" data-aos-delay={200}>
                                                <img className="col-xl-5" style={{ backgroundSize: 'cover', height: '300px', borderRadius: '20px' }} src={`${MainUrl}/${item.image}`} />
                                                <div className="col-xl-6 mx-3">
                                                    <div>
                                                        <h3 className="mb-3 text-center" style={{ color: '#fc6c14' }}>{item.subTitle}</h3>

                                                        <p style={{ fontStyle: 'italic', fontWeight: '600', lineHeight: '2.4' }}>
                                                            {item.description.split(" ").map((el, i) => (
                                                                <motion.span
                                                                    initial={{ opacity: 0 }}
                                                                    animate={{ opacity: 1 }}
                                                                    transition={{
                                                                        duration: 0.01,
                                                                        delay: i / 10
                                                                    }}
                                                                    key={i}
                                                                >
                                                                    {el}{" "}
                                                                </motion.span>
                                                            ))}
                                                        </p>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        ) : null
                }
            </section>{/* End Why Choose Us Section */}

        </>
    )
}

export default WhyChooseUs
