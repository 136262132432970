import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { styled, alpha, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
    // @ts-ignore
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));


export default function TopBar({ open, handleDrawerOpen, setMode }) {

    const theme = useTheme();

    return (
        <AppBar position="fixed"
            // @ts-ignore
            open={open}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 5,
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>

                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                    />

                </Search>


                <Box flexGrow={1}>
                </Box>

                <Stack direction='row' gap={0} sx={{ flexWrap: 'wrap' }}>
                    <IconButton onClick={() => {
                        window.location.pathname = 'admin/home'
                    }} color='inherit'>
                        <HomeOutlinedIcon />
                    </IconButton>

                    {
                        theme.palette.mode === 'light' ?
                            (
                                <IconButton onClick={() => {
                                    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
                                    localStorage.setItem('CurrentMode', 'dark')
                                }} color={'inherit'}>
                                    <DarkModeOutlinedIcon />
                                </IconButton>
                            ) :
                            (
                                <IconButton onClick={() => {
                                    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
                                    localStorage.setItem('CurrentMode', 'light')
                                }} color={'inherit'}>
                                    <LightModeOutlinedIcon />
                                </IconButton>
                            )
                    }

                    <IconButton color='inherit' onClick={() => {
                        window.location.pathname = '/admin/accountinfo'
                    }}>
                        <SettingsOutlinedIcon />
                    </IconButton>

                    <IconButton color='inherit' onClick={() => {
                        window.location.pathname = '/login'
                    }}>
                        <ExitToAppOutlinedIcon />
                    </IconButton>

                </Stack>

            </Toolbar>
        </AppBar >

    )
}
