import BaseUrl from '../../API/BaseUrl';
import { createcontactmessage, getallcontactmsg } from './../Types';


/// Create Contact Us Message On UI Without Token  
const CreateContactMessage =(contactData)=> async(dispatch)=>
{
    try
    {
        const res = await BaseUrl.post('/api/CustomerMessage/v1/CreateCustomerMessage', contactData);
        //console.log(res.status);
        dispatch({            
            type: createcontactmessage, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: createcontactmessage, 
            data: 'The Error is' + e.response
        })
    }
}


/// Get All Contact Us Message 
const GetAllContactMessageActions =(data)=> async(dispatch)=>
{
    const config = {
        headers : {
            Authorization : `Bearer ${localStorage.getItem('UserLoginToken')}`
        }
    }
    try
    {
        const res = await BaseUrl.post('/api/CustomerMessage/v1/GetAllCustomerMessage',data, config);
        //console.log(res.status);
        dispatch({            
            type: getallcontactmsg, 
            data: res,      
            status : res.status      
        })
    }
    catch(e)
    {
        dispatch({
            type: getallcontactmsg, 
            data: 'The Error is' + e.response
        })
    }
}



export { CreateContactMessage, GetAllContactMessageActions }