import React from 'react'
import AdminEditServiceDetailsItem from './../../../../Components/Admin/AdminServices/AdminServiceDetails/AdminEditServiceDetailsItem';

const AdminEditSDetailsItemPage = () => {
    return (
        <>
           <AdminEditServiceDetailsItem />
        </>
    )
}

export default AdminEditSDetailsItemPage
