import HomePage from './Pages/UI Pages/HomePage';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ContactPage from './Pages/UI Pages/ContactPage';
import ServicesPage from './Pages/UI Pages/ServicesPage';
import ServiceDetailsPage from './Pages/UI Pages/ServiceDetailsPage';
import TeamPage from './Pages/UI Pages/TeamPage';
import AboutPage from './Pages/UI Pages/AboutPage';
import JobsPage from './Pages/UI Pages/JobsPage';
import LoginPage from './Pages/Admin/Authentication/LoginPage';
import AdminPageParent from './Pages/Admin/AdminPageParent';
import AdminContactPage from './Pages/Admin/ContactUs/AdminContactPage';
import AdminHomePage from './Pages/Admin/Home/AdminHomePage';
import AdminBrandsPage from './Pages/Admin/Brands/AdminBrandsPage';
import AdminReviewsPage from './Pages/Admin/Reviews/AdminReviewsPage';
import PContentContainerPage from './Pages/Admin/PageContent/PContentContainerPage';
import AdminEditReviewPage from './Pages/Admin/Reviews/AdminEditReviewPage';
import ContactInfoPage from './Pages/Admin/ContactInfo/ContactInfoPage';
import AdminEditBrandPage from './Pages/Admin/Brands/AdminEditBrandPage';
import EditPContentPage from './Pages/Admin/PageContent/EditPContentPage';
import AddPContentPage from './Pages/Admin/PageContent/AddPContentPage';
import AccountInfoPage from './Pages/Admin/AccountInfo/AccountInfoPage';
import AdminServicesPage from './Pages/Admin/Services/AdminServicesPage';
import ProtectedRouteHook from './CustomHooks/Authentication/ProtectedRouteHook';
import ProtectedRoute from './Components/Utility/ProtectedRoute';
import AdminEditServicePage from './Pages/Admin/Services/AdminEditServicePage';
import AdminServiceDetailsPage from './Pages/Admin/Services/ServiceDetails/AdminServiceDetailsPage';
import AdminEditSDetailsItemPage from './Pages/Admin/Services/ServiceDetails/AdminEditSDetailsItemPage';
import DepositPage from './Pages/UI Pages/DepositPage';
import JoinUsPage from './Pages/UI Pages/JoinUsPage';


function App() {

  const [, isAdmin] = ProtectedRouteHook()

  return (
    <div>

      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/services' element={<ServicesPage />} />
          <Route path='/servicedetails/:id' element={<ServiceDetailsPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/deposits' element={<DepositPage />} />
          <Route path='/team' element={<TeamPage />} />
          <Route path='/jobs' element={<JobsPage />} />
          <Route path='/joinus' element={<JoinUsPage />} />

          <Route path='/login' element={<LoginPage />} />

          <Route element={<ProtectedRoute PermissionFlag={isAdmin} />}>

            <Route element={<AdminPageParent />}>


              <Route path='/admin/home' element={<AdminHomePage />} />

              <Route path="/admin/contact" element={<AdminContactPage />} />

              <Route path="/admin/brands" element={<AdminBrandsPage />} />
              <Route path="admin/editbrand/:id" element={<AdminEditBrandPage />} />

              <Route path="/admin/reviews" element={<AdminReviewsPage />} />
              <Route path="/admin/editreview/:id" element={<AdminEditReviewPage />} />

              <Route path="/admin/pagecontent" element={<PContentContainerPage />} />
              <Route path="/admin/editpagecontent/:id" element={<EditPContentPage />} />
              <Route path="/admin/addpagecontent/:name" element={<AddPContentPage />} />


              <Route path="/admin/contactinfo" element={<ContactInfoPage />} />


              <Route path="/admin/accountinfo" element={<AccountInfoPage />} />


              <Route path="/admin/services" element={<AdminServicesPage />} />
              <Route path="/admin/editserviceitem/:id" element={<AdminEditServicePage />} />
              <Route path="/admin/getservicedetails/:id" element={<AdminServiceDetailsPage />} />
              <Route path="/admin/editservicedetails/:id" element={<AdminEditSDetailsItemPage />} />

            </Route>


          </Route>



        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
