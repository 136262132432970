import GetAllPublicPageContentWithNameHook from './../../CustomHooks/PageContent/GetAllPublicPageContentWithNameHook';
import { MainUrl } from './../../Redux/Types';
import { Row, Col, Container } from 'react-bootstrap';

const DepositsContainer = () => {

    const [getallpublicpagecontent] = GetAllPublicPageContentWithNameHook('Deposits')

    // console.log(getallpublicpagecontent);
    return (
        <>
            {/* ======= Team Section ======= */}
            <section id="team" className="team">
                <div className="container" data-aos="fade-up">
                    <div className="section-header">
                        <h2>طرق الايداعات</h2>
                    </div>
                    <Container className='my-2'>
                        {
                            getallpublicpagecontent && getallpublicpagecontent.data ?
                                (
                                    getallpublicpagecontent.data.data.map((item, index) => {
                                        if (index % 2 === 0) {
                                            return <Row className='flex-wrap mt-3' key={index} data-aos="fade-up" data-aos-delay={300}>
                                                <Col sm='12' md='6'>
                                                    <img src={`${MainUrl}/${item.image}`} alt='' style={{ borderRadius: '50%', maxHeight: '100%', maxWidth: '95%', objectFit: 'fill' }} />
                                                </Col>
                                                <Col sm='12' md='6' className='mt-1 py-4'>
                                                    <h3 className='text-center mb-4' style={{ color: '#fc6c14' }}>{item.title}</h3>
                                                    <p style={{ lineHeight: '2.3' }}>{item.description}</p>
                                                </Col>
                                                {/* {
                                                    index != getallpublicpagecontent.data.data.length - 1 ?
                                                        <hr className='my-4' style={{ backgroundColor: '#0400F0', margin: 'auto', height: '3px', maxWidth: '70%' }} />
                                                        : null
                                                } */}
                                            </Row>
                                        }
                                        else {
                                            return <Row style={{ flexDirection: 'row-reverse' }} className='flex-wrap mt-3' key={index} data-aos="fade-up" data-aos-delay={300}>
                                                <Col sm='12' md='6'>
                                                    <img src={`${MainUrl}/${item.image}`} alt='' style={{ borderRadius: '50%', maxHeight: '100%', maxWidth: '90%', objectFit: 'fill' }} />
                                                </Col>
                                                <Col sm='12' md='6' className='mt-1 py-4'>
                                                    <h3 className='text-center mb-4' style={{ color: '#fc6c14' }}>{item.title}</h3>
                                                    <p style={{ lineHeight: '2.3' }}>{item.description}</p>
                                                </Col>
                                                {/* {
                                                    index != getallpublicpagecontent.data.data.length - 1 ?
                                                        <hr className='my-4' style={{ backgroundColor: '#0400F0', margin: 'auto', height: '3px',  maxWidth: '70%' }} />
                                                        : null
                                                } */}

                                            </Row>
                                        }

                                    })
                                ) : null
                        }
                    </Container>
                </div>
            </section>

        </>
    )
}

export default DepositsContainer
