import React from 'react';
import { MDBInput } from 'mdb-react-ui-kit';
import LoginHook from '../../../CustomHooks/Authentication/LoginHook';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';
import { useEffect } from 'react';


function LoginPage() {

    const [email, password, , changeemail, changepassword, loginsubmit] = LoginHook()

    useEffect(() => {
        if (Boolean(localStorage.getItem('UserLoginToken'))) {
            localStorage.removeItem('UserLoginToken')
        }
    }, [])


    return (
        <Container className="fw-bold gradient-form">

            <Row style={{ minHeight: '12vh' }}>

            </Row>


            <Row>

                <Col sm='6' className="mb-5">
                    <div className="d-flex flex-column ms-5">

                        <div className="text-center">
                            <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"
                                style={{ width: '185px' }} alt="logo" />
                            <h4 className="mt-1 mb-5 pb-1">لوحة تحكم موجة</h4>
                        </div>

                        <p className='text-center h2 my-4'>تسجيل الدخول</p>


                        <MDBInput value={email} wrapperClass='mb-4' label='اسم المستخدم' id='form1' type='email' onChange={changeemail} />
                        <MDBInput value={password} wrapperClass='mb-4' label='كلمة المرور' id='form2' type='password' onChange={changepassword} />


                        <div className="text-center pt-1 mb-5 pb-1">
                            <Button type='submit' onClick={loginsubmit} className="mb-4 w-100 gradient-custom-2">تسجيل الدخول</Button>
                            <a className="text-muted" href="#!">نسيت كلمة المرور؟</a>
                        </div>

                    </div>

                </Col>

                <Col sm='6' className="">
                    <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">

                        <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                            <h4 className="h1 text-center mb-4">أكبر من مجرد شركة</h4>
                        </div>

                    </div>

                </Col>

            </Row>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Flip}
            />


        </Container>
    );
}

export default LoginPage;