import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import notify from './../../CustomHooks/useNotification';
import { CreateUserOnNewPay, UploadNewPayImage } from '../../Redux/Actions/JoinUsActions';

const JoinUsHook = () => {

    const [loadingimg, setloadingimg] = useState(null)
    const [loadingUser, setloadingUser] = useState(null)
    const [validimages, setvalidimages] = useState(null)


    const [selectedFile, setselectedFile] = useState(null)
    const [Images, setImages] = useState([])
    const [tempArr, settempArr] = useState([])


    const [AttachmentID, setAttachmentID] = useState('')
    const myFormData = new FormData()


    const [firstname, setFirstName] = useState('')
    const [lastname, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [username, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [mobile, setMobile] = useState('')
    const [nationalid, setNationalId] = useState('')
    const [shopName, setShopName] = useState('')
    const [appValue, setAppValue] = useState(1)

    const dispatch = useDispatch();

    const uploadnewpayimage = useSelector(state => state.JoinUsRed.uploadnewpayimageres)
    const createnewpayuser = useSelector(state => state.JoinUsRed.createnewpayuserres)

    const firstNameChange = (e) => {
        setFirstName(e.target.value)
    }

    const lastNameChange = (e) => {
        setLastName(e.target.value)
    }

    const emailchange = (e) => {
        setEmail(e.target.value)
    }

    const userNameChange = (e) => {
        setUserName(e.target.value)
    }

    const passwordChange = (e) => {
        setPassword(e.target.value)
    }

    const mobilechange = (e) => {
        setMobile(e.target.value)
    }

    const nationalidChange = (e) => {
        setNationalId(e.target.value)
    }

    const shopNameChange = (e) => {
        setShopName(e.target.value)
    }

    const ApplListChangeValue = (e) => {
        setAppValue(e.target.value)
        // console.log(e.target.value);
    }

    ///// On Change Image 
    const onImageChange = async (e) => {
        setselectedFile(null)
        setImages([])

        if (e.target.files.length > 0) {
            Object.values(e.target.files).map(item => {
                Images.push(item)
            })
            await setselectedFile(Images)
        }
    }

    /// Clicking The Button & Save Contact Message
    const CreateNewPayUser = async (e) => {
        e.preventDefault();        
        if (firstname === '' || lastname === '' || email === '' || username === '' || password === '' || mobile === '' || nationalid === '' || shopName === '') {
            notify('يرجي استكمال البيانات', 'warning')
            return;
        }
        if (mobile.length != 11) {
            notify('رقم هاتف غير صالح', 'warning')
            return;
        }
        if (nationalid.length != 14) {
            notify('رقم بطاقة غير صالح', 'warning')
            return;
        }
        if (selectedFile === null) {
            notify('يجب اختيار صورة واحدة علي الأقل', 'warning')
            return;
        }
        settempArr([])
        selectedFile.find(img => {
            if (img.type !== 'image/png') {
                notify('يجب اختيار امتداد للصور PNG فقط', 'warning')
                tempArr.push(img)
                settempArr(tempArr)
                return;
            }
        })
        setvalidimages(true)
    }

    /// On Finishing Successful Images Validation
    useEffect(() => {
        const Run = async () => {
            /// Indicate That The Images all From PNG Extension
            if (!tempArr.length && validimages === true) {
                setloadingimg(true)
                selectedFile.map(img => {
                    myFormData.append("fileUpload.File", img);
                })
                await dispatch(UploadNewPayImage(myFormData))
                setloadingimg(false)
            }
        }
        Run()
    }, [tempArr, validimages])


    /// On Finishing Suucessful Image Upload 

    useEffect(() => {
        const Run = async () => {
            if (loadingimg === false) {
                if (uploadnewpayimage && uploadnewpayimage.data && uploadnewpayimage.status === 200) {
                    // console.log(uploadnewpayimage);
                    setAttachmentID(uploadnewpayimage.data.Data);
                }
                else {
                    notify('يوجد مشكلة في رفع الصورة ، حاول لاحقا', 'error')
                }
            }
        }
        Run()

    }, [loadingimg])


    /// Updating Attachment Id & Calling Another API To Save Data

    useEffect(() => {
        if (AttachmentID != '') {
            InsertData(AttachmentID)
        }
    }, [AttachmentID])

    /// Method For Sending & Saving User Data 

    const InsertData = async (ImgID) => {
        setloadingUser(true)
        await dispatch(CreateUserOnNewPay({
            "FirstName": firstname,
            "LastName": lastname,
            "UserName": username,
            "Email": email,
            "Password": password,
            "PhoneNumber": mobile,
            "NationlID": nationalid,
            "ShopName": shopName,
            "VillageId": 92,
            "AppID1": "xxx2",
            "AppID2": "xxx62",
            "AppID3": "xxx52",
            "AppType": appValue,
            "attachmentsIds": [
                ImgID
            ]
        }))
        setloadingUser(false)
    }


    /// On Finishing Sending Reseller / Agent / Individual Data

    useEffect(() => {
        const Run = async () => {
            if (loadingUser === false) {
                if (createnewpayuser && createnewpayuser.data && createnewpayuser.status === 200) {
                    if (createnewpayuser.data.Code == 403) {
                        // console.log(createnewpayuser.data);
                        if (createnewpayuser.data.Message === `مسجل من قبل  ${nationalid} :`) {
                            notify('رقم البطاقة مسجلة من قبل لدي شركة موجة ، يرجي التغيير', 'error')

                        }
                        if (createnewpayuser.data.Message === `مسجل من قبل  ${username} :`) {
                            notify('اسم المستخدم مسجل من قبل لدي شركة موجة ، يرجي التغيير', 'error')

                        }
                        if (createnewpayuser.data.Message === `مسجل من قبل  ${email} :`) {
                            notify('البريد الالكتروني مسجل من قبل لدي شركة موجة ، يرجي التغيير', 'error')

                        }
                        if (createnewpayuser.data.Message === `مسجل من قبل  ${mobile} :`) {
                            notify('رقم الموبايل مسجل من قبل لدي شركة موجة ، يرجي التغيير', 'error')

                        }
                        setvalidimages(false)
                    }
                    else {
                        // console.log(createnewpayuser);
                        setFirstName('')
                        setLastName('')
                        setEmail('')
                        setUserName('')
                        setPassword('')
                        setMobile('')
                        setNationalId('')
                        setShopName('')
                        setAppValue(1)
                        setselectedFile(null)
                        setImages([])
                        document.getElementById('ImageInput').value= null;
                        notify('تم ارسال البيانات بنجاح', 'success')
                    }
                }
                else {
                    notify('يوجد مشكلة في ارسال البيانات ، حاول لاحقا', 'error')
                }
            }
        }
        Run()
    }, [loadingUser])

    return [firstname, firstNameChange, lastname, lastNameChange, email, emailchange, username, userNameChange,
        password, passwordChange, mobile, mobilechange, nationalid, nationalidChange, shopName, shopNameChange, CreateNewPayUser
        , ApplListChangeValue, onImageChange, loadingimg, loadingUser]
}


export default JoinUsHook
