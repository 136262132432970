/*export const MainUrl = 'http://walidatia202024-001-site1.anytempurl.com'*/
export const MainUrl = 'https://infoadmin.mogapayment.com'

export const loginuser = 'LOGIN_USER'

export const getinfo = 'GET_ACCOUNT_INFO'

export const createbrand = 'CREATE_BRAND'

export const getallbrands = 'GET_ALL_BRANDS'

export const getallactivebrands = 'GET_ALL_ACTIVE_BRANDS'

export const getonebrand = 'GET_ONE_BRAND'

export const editbrand = 'EDIT_BRAND'

export const getallpublicbrands = 'GET_ALL_PUBLIC_BRANDS'

export const createattachment = 'CREATE_ATTACHMENT'

export const getallpagecontent = 'GET_ALL_PAGE_CONTENT'

export const createpagecontent = 'CREATE_PAGE_CONTENT'

export const bindsectionwithimage = 'BIND_SECTION_WITH_IMAGE'

export const updatebindsectionwithimage = 'UPDATE_BIND_SECTION_WITH_IMAGE'

export const getpagecontentwithid = 'GET_PAGE_CONTENT_WITH_ID'

export const getallpublicpagecontent = 'GET_PUBLIC_PAGE_CONTENT'

export const updatepagecontent = 'UPDATE_PAGE_CONTENT'

export const createcontactmessage = 'CREATE_CONTACT_MESSAGE'

export const getallcontactmsg = 'GET_ALL_CONTACT_MSG'

export const updatecontactinfo = 'UPDATE_CONTACT_INFO'

export const getcontactinfo = 'GET_CONTACT_INFO'

export const getpubliccontactinfo = 'GET_PUBLIC_CONTACT_INFO'

export const getallgallery = 'GET_ALL_GALLERY'

export const creategalleryitem = 'CREATE_GALLERY_ITEM'

export const getonegallery = 'GET_ONE_GALLERY_ITEM'

export const updategalleryitem = 'UPDATE_GALLERY_ITEM'

export const getallpublicgallery = 'GET_PUBLIC_GALLERY'

export const createalbumitem = 'CREATE_GALLERY_ALBUM'

export const updatealbumitem = 'UPDATE_GALLERY_ALBUM_ITEM'

export const createreviewitem = 'CREATE_REVIEW_ITEM'

export const getallreviews = 'GET_ALL_REVIEWS'

export const updatereviewitem = 'UPDATE_REVIEW_ACTION'

export const getonereview = 'GET_ONE_REVIEW'

export const getallpublicreviews = 'GET_ALL_PUBLIC_REVIEWS'

export const getaccountinfo = 'GET_ACCOUNT_INFO'

export const updateadminpw = 'UPDATE_ADMIN_PASSWORD'

export const createnewpayuser = 'CREATE_USER_NEW_PAY'

export const uplaodnewpayimage = 'UPLOAD_NEW_PAY_IMAGE'














