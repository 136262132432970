import { getaccountinfo, updateadminpw } from '../Types';

const initial = 
{
    getaccountinfores : [],
    updateadminpwres : [],
    status: ''
}

const AccountInfoReducer = (state = initial , action)=>
{
    switch(action.type)
    {
        case getaccountinfo: 
            return{
                getaccountinfores : action.data,
                status : action.status
        }
        case updateadminpw: 
            return{
                updateadminpwres : action.data,
                status : action.status
        }
        default:
            return state
    }

}

export default AccountInfoReducer;